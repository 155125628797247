import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import { AppDispatch, AppState } from '../redux/reducer';

/**
 * Constants
 **/
export const moduleName = 'audio';

export const CLEAR = `${moduleName}/CLEAR`;
export const PLAY = `${moduleName}/PLAY`;
export const PAUSE = `${moduleName}/PAUSE`;

export interface AudioState {
	audio: any;
}

export interface AudioAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		audio?: string;
	};
}

export const initialState: AudioState = {
	audio: null,
};

/**
 * Reducer
 **/
export default (state: AudioState = initialState, action: AudioAction): AudioState => {
	const { type, payload } = action;

	switch (type) {
		case CLEAR:
			if (state.audio) state.audio.pause();
			return { ...state, audio: null };
		case PAUSE:
			if (state.audio) state.audio.pause();
			return { ...state };
		case PLAY: {
			return { ...state, audio: payload!.audio };
		}
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const clear = () => ({
	type: CLEAR,
});

export const pause = () => ({
	type: PAUSE,
});

export const play = (audio: any) => ({
	type: PLAY,
	payload: { audio },
});

export const playLink = (link: string) => (dispatch: AppDispatch) => {
	const audio = new Audio(link);
	audio.play();
	dispatch(play(audio));
};

/**
 * Selectors
 **/
export const audioSelector = (state: AppState) => state.audio;
export const audioAudioSelector = createSelector(audioSelector, audio => audio.audio);
