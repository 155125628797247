import React from 'react';
import { Redirect } from 'react-router-dom';

import useRequireAuth from 'hooks/useRequireAuth';
import Preloader from 'common/Preloader/Preloader';

const IndexRoute: React.FC = () => {
	const isAuth = useRequireAuth();
	if (!isAuth) return <Preloader />;

	return <Redirect to={'/dashboard'} />;
};

export default IndexRoute;
