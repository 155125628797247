import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './user-edit.scss';
import { editUserEditSelector, editUserErrorsSelector, getUser, removeUser, saveUser, setField } from 'ducks/edit/user';
import { urlBackend } from '../../../config';
import UploadDto from 'dto/UploadDto';
import Svg from 'common/Svg';
import CheckBox from 'common/CheckBox/CheckBox';
import CommentsList from './CommentsList/CommentsList';

const UserEdit: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const [isChangePassword, setChangePassword] = useState(false);
	const [pageTitle, setPageTile] = useState('Добавление пользователя');
	const [avatarFile, setAvatarFile] = useState<string | null>(null);
	const user = useSelector(editUserEditSelector, shallowEqual);
	const errors = useSelector(editUserErrorsSelector, shallowEqual);

	useEffect(() => {
		if (id === 'add') {
			setPageTile('Добавление пользователя');
			dispatch(getUser());
		} else {
			setPageTile('Редактирование пользователя');
			dispatch(getUser(parseInt(id)));
		}
	}, [id]);

	const { email, name, avatar, isAdmin, password, repeat } = user;

	const handleAllowChangePassword = () => {
		dispatch(setField('password', null));
		dispatch(setField('repeat', null));
		setChangePassword(!isChangePassword);
	};

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('email', event.target.value));
	};

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('name', event.target.value));
	};

	const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setAvatarFile(reader.result as string);
			};
			dispatch(setField('avatar', event.target!.files![0]));
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const handleChangeAdmin = () => {
		dispatch(setField('isAdmin', !isAdmin));
	};

	const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('password', event.target.value));
	};

	const handleChangeRepeat = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('repeat', event.target.value));
	};

	const handleDelete = () => {
		if (confirm('Вы уверены?')) {
			dispatch(removeUser(parseInt(id)));
		}
	};

	const handleSave = () => {
		dispatch(saveUser());
	};

	const renderAvatar = () => {
		if (avatarFile) {
			return <img src={avatarFile} />;
		} else if (avatar) {
			return <img src={`${urlBackend}/upload/${(avatar as UploadDto).path}`} />;
		}
		return <Svg name="arrow-up" className="museum-edit__icon-up" width="42" height="42" />;
	};

	return (
		<div className="museum-edit">
			<legend className="legend sec-title">{pageTitle}</legend>
			<div className="fieldset__row">
				<div className="fieldset__col">
					<fieldset className="fieldset">
						<label className="label">
							<legend>Email:</legend>
							<input
								type="text"
								className="input"
								placeholder="email"
								value={email}
								onChange={handleChangeEmail}
							/>
							{errors.email && <div className="error">{errors.email}</div>}
						</label>
					</fieldset>
					<fieldset className="fieldset">
						<label className="label">
							<legend>Имя пользователя:</legend>
							<input
								type="text"
								className="input"
								placeholder="имя"
								value={name || ''}
								onChange={handleChangeName}
							/>
						</label>
					</fieldset>
					{isChangePassword || id === 'add' ? (
						<>
							<fieldset className="fieldset">
								<label className="label">
									<legend>Новый пароль:</legend>
									<input
										type="password"
										className="input"
										placeholder="password"
										value={password || ''}
										onChange={handleChangePassword}
									/>
									{errors.password && <div className="error">{errors.password}</div>}
								</label>
							</fieldset>
							<fieldset className="fieldset">
								<label className="label">
									<legend>Повторите пароль:</legend>
									<input
										type="password"
										className="input"
										placeholder="password"
										value={repeat || ''}
										onChange={handleChangeRepeat}
									/>
									{errors.repeat && <div className="error">{errors.repeat}</div>}
								</label>
							</fieldset>
							{id !== 'add' && (
								<fieldset className="fieldset">
									<button className="btn" onClick={handleAllowChangePassword}>
										отмена
									</button>
								</fieldset>
							)}
						</>
					) : (
						<fieldset className="fieldset">
							<button className="btn" onClick={handleAllowChangePassword}>
								сменить пароль
							</button>
						</fieldset>
					)}
				</div>
				<div className="fieldset__col">
					<fieldset className="fieldset">
						<label className="label">
							<CheckBox label="Администратор" checked={isAdmin} onClick={handleChangeAdmin} />
						</label>
					</fieldset>
					<label className="label">
						<legend>Аватар:</legend>
						<div className="file-info small">
							{renderAvatar()}
							<input type="file" accept=".jpg,.jpeg,.png" onChange={handleChangeAvatar} />
							<span>Загрузить</span>
						</div>
					</label>
				</div>
			</div>
			<div className="museum-edit__buttons">
				{id !== 'add' && (
					<button className="btn delete" onClick={handleDelete}>
						Удалить
					</button>
				)}
				<button className="btn submit" onClick={handleSave}>
					Сохранить
				</button>
			</div>
			<CommentsList user={user} />
		</div>
	);
};

export default UserEdit;
