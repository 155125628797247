import { combineReducers } from 'redux';

import museums, { DataMuseumsState, initialState as DataMuseumsInitialState } from './museums';
import users, { DataUsersState, initialState as DataUsersInitialState } from './users';
import exhibits, { DataExhibitsState, initialState as DataExhibitsInitialState } from './exhibits';
import comments, { DataCommentsState, initialState as DataCommentsInitialState } from './comments';

export interface DataState {
	museums: DataMuseumsState;
	users: DataUsersState;
	exhibits: DataExhibitsState;
	comments: DataCommentsState;
}
export const dataSelector = (state: { data: DataState }): DataState => state.data;

export const initialState: DataState = {
	museums: DataMuseumsInitialState,
	users: DataUsersInitialState,
	exhibits: DataExhibitsInitialState,
	comments: DataCommentsInitialState,
};

export default combineReducers<DataState>({
	museums,
	users,
	exhibits,
	comments,
});
