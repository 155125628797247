import { UiState, initialState as UiInitialState } from 'ducks/ui';
import { initialState as PreloadersInitialState } from 'ducks/ui/preloaders';
import { initialState as AuthInitialState, AuthState } from 'ducks/auth';

const storageKey = 'musetory';
class LocalStorage {
	private storage: Storage | null;

	constructor() {
		this.storage = this.__supportsHtml5Storage() ? window.localStorage : null;
	}

	__supportsHtml5Storage(): boolean {
		try {
			return 'localStorage' in window && window.localStorage !== null;
		} catch (exc) {
			return false;
		}
	}

	saveInterface(ui: UiState): void {
		if (!this.storage) {
			return;
		}
		this.storage.setItem(`${storageKey}-ui`, JSON.stringify(ui));
	}

	fetchInterface(): UiState {
		const uiStored = this.storage && this.storage.getItem(`${storageKey}-ui`);
		if (uiStored) {
			const ui = JSON.parse(uiStored) as UiState;
			return { ...ui, preloaders: PreloadersInitialState };
		}
		return UiInitialState;
	}

	saveAuth(auth: AuthState) {
		if (!this.storage) {
			return;
		}
		this.storage.setItem(`${storageKey}-auth`, JSON.stringify(auth));
	}

	fetchAuth(): AuthState {
		const authStored = this.storage && this.storage.getItem(`${storageKey}-auth`);
		if (authStored) {
			const auth = JSON.parse(authStored) as AuthState;
			return auth;
		}
		return AuthInitialState;
	}

	clear(): void {
		if (this.storage) {
			this.storage.clear();
		}
	}
}

export default new LocalStorage();
