import thunk from 'redux-thunk';
import { AnyAction, applyMiddleware, compose, createStore, Store } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer, { AppState } from './reducer';
import history from './history';

// const middleware = routerMiddleware(history);
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}): Store<AppState, AnyAction> {
	const store = createStore(
		createRootReducer(history),
		initialState,
		compose(applyMiddleware(thunk, routerMiddleware(history))),
	);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducer', () => {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const nextRootReducer = require('./reducer');
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
}
