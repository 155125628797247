import React from 'react';
import { CityDto } from 'dto/CityDto';
import { Link } from 'react-router-dom';
import Svg from 'common/Svg';

export interface CityCatalogItemProps {
	city: CityDto;
}

export const CityCatalogItem: React.FC<CityCatalogItemProps> = ({ city }) => {
	const { title, id } = city;
	return (
		<tr>
			<td className="big">{title}</td>
			<td>
				<div className="table__bar">
					<Link to={`/city/edit/${id}`} className="table__btn">
						<Svg name="edit" />
					</Link>
				</div>
			</td>
		</tr>
	);
};
