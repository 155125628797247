import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { dataMuseumsListSelector, getMuseumsList } from 'ducks/data/museums';
import UploadDto from 'dto/UploadDto';

export interface MenuItemType {
	title: string;
	ico?: string | null;
	link?: string;
	submenu?: MenuItemType[];
	open?: boolean;
	onClick?: () => void;
	active?: () => boolean;
}

export default (): MenuItemType[] => {
	const dispatch = useDispatch();
	const museumsList = useSelector(dataMuseumsListSelector, shallowEqual);

	useEffect(() => {
		dispatch(getMuseumsList());
	}, []);

	return [
		{
			title: 'Dashboard',
			ico: 'dashboard',
			link: '/dashboard',
		},
		{
			title: 'Пользователи',
			ico: 'team',
			link: '/users',
			open: true,
			submenu: [
				{
					title: 'Добавить',
					link: '/users/add',
				},
			],
		},
		{
			title: 'Музеи',
			ico: 'museum',
			open: true,
			link: '/museum',
			submenu: [
				...museumsList.map(item => ({
					title: item.title || '????',
					ico: (item.ico as UploadDto)?.path || null,
					link: `/museum/${item.id}`,
				})),
				{
					title: 'Добавить',
					link: '/museum/edit/add',
				},
			],
		},
		{
			title: 'Комментарии',
			ico: 'comments',
			link: '/comments',
		},
	];
};
