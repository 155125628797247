import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import './exhibits-catalog.scss';
import { dataExhibitsListSelector, getExhibitsList } from 'ducks/data/exhibits';
import Svg from 'common/Svg';
import { editMuseumEditSelector, getMuseumShort } from 'ducks/edit/museum';
import ExhibitsCatalogItem from 'components/MuseumPage/ExhibitsCatalog/ExhibitsCatalogItem';
import { clear, pause, audioAudioSelector, playLink } from 'ducks/audio';
import QRModal from 'common/QRModal/QRModal';
import { rus_to_latin } from 'utils/string';
import Modal from 'react-responsive-modal';

const ExhibitsCatalog: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const [qr, setQR] = useState<{ code: string; title: string } | null>(null);
	const [nowPlay, setNowPlay] = useState<number | null>(null);
	const exhibitsList = useSelector(dataExhibitsListSelector, shallowEqual);
	const museum = useSelector(editMuseumEditSelector, shallowEqual);
	const audio = useSelector(audioAudioSelector, shallowEqual);

	useEffect(() => {
		return () => {
			dispatch(clear());
		};
	}, []);

	useEffect(() => {
		const museumId = parseInt(id);
		if (!isNaN(museumId)) {
			dispatch(getExhibitsList(museumId));
			dispatch(getMuseumShort(museumId));
		}
	}, [id]);

	const handleAdd = () => {
		dispatch(push(`/museum/exhibit/${id}/add`));
	};

	const handlePlayAudio = (exhibitId: number, link: string) => {
		if (audio) {
			dispatch(pause());
			if (nowPlay !== exhibitId) {
				setNowPlay(exhibitId);
				dispatch(playLink(link));
			} else {
				setNowPlay(null);
			}
		} else {
			setNowPlay(exhibitId);
			dispatch(playLink(link));
		}
	};

	const onCloseModal = () => {
		setQR(null);
	};

	const onShowModal = (code: string, title: string) => {
		setQR({ code, title });
	};

	return (
		<div className="exhibits-catalog">
			<div className="exhibits-catalog__filter">
				<legend className="legend sec-title">{`${museum.title}: Список экспонатов`}</legend>
				<button className="btn submit" onClick={handleAdd}>
					<Svg name="plus" />
					Добавить
				</button>
			</div>
			{exhibitsList && exhibitsList.length > 0 && (
				<div className="table">
					<div className="table__wrap">
						<table>
							<thead>
								<tr>
									<th>
										<div className="table__head">Название</div>
									</th>
									<th className="big">
										<div className="table__head">Изображения</div>
									</th>
									<th>
										<div className="table__head">Статистика</div>
									</th>
									<th className="center">
										<div className="table__head">MP3</div>
									</th>
									<th className="center">
										<div className="table__head">QR код</div>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{exhibitsList.map(item => (
									<ExhibitsCatalogItem
										exhibit={item}
										key={item.id}
										isPlay={nowPlay === item.id}
										onPlay={handlePlayAudio}
										onShowModal={onShowModal}
									/>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)}
			{qr && (
				<Modal open={qr != null} onClose={onCloseModal} center>
					<QRModal value={qr.code || ''} title={rus_to_latin(`${museum.title}_${qr.title}`)} />
				</Modal>
			)}
		</div>
	);
};

export default ExhibitsCatalog;
