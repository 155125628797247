import UploadDto from './UploadDto';
import ExhibitDto from './ExhibitDto';
import { CityDto } from 'dto/CityDto';

export interface MuseumsTags {
	person: string;
	military: string;
	history: string;
	technical: string;
	art: string;
	etno: string;
	factory: string;
	proffessional: string;
	village: string;
}

export const museumsTagsList: MuseumsTags = {
	person: 'Личность',
	military: 'Военный',
	history: 'Исторический',
	technical: 'Технический',
	art: 'Искусства',
	etno: 'Этнографический',
	factory: 'Предприятия',
	proffessional: 'Профессии',
	village: 'Усадьба',
};

export default interface MuseumDto {
	id?: number;
	icoId?: number | null;
	ico?: UploadDto | File | null;
	cityId?: number | null;
	city?: CityDto;
	title?: string;
	description?: string | null;
	www?: string | null;
	phone?: string | null;
	address?: string | null;
	lat?: string | null;
	lon?: string | null;
	rating?: number;
	exhibits?: ExhibitDto[];
	exhibits_count?: number;
	seen_exhibits_count?: number;
	active?: boolean;
	tags?: (keyof MuseumsTags)[];
}
