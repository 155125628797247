import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './header.scss';
import ProfileBar from 'shared/Header/ProfileBar/ProfileBar';
import { setNavBarOpen, uistatusIsNavBarOpenSelector } from 'ducks/ui/status';
import HeaderWarning from 'shared/Header/HeaderWarning';

const Header: React.FC = () => {
	const dispatch = useDispatch();
	const isNavOpen = useSelector(uistatusIsNavBarOpenSelector, shallowEqual);

	const handleOpenNavBar = () => {
		dispatch(setNavBarOpen(!isNavOpen));
	};

	return (
		<header className="header">
			<div className="header__wrap">
				<div className="header__bar">
					<button className={`btn burger ${isNavOpen ? 'open' : 'close'}`} onClick={handleOpenNavBar}>
						<span></span>
						<span></span>
						<span></span>
					</button>
					<Link to="/" className="logo">
						Musetory
					</Link>
				</div>
				<HeaderWarning />
				<div className="header__bar">
					<ProfileBar />
				</div>
			</div>
		</header>
	);
};

export default Header;
