const isObject = (value: any) => value === Object(value);

const isArray = (value: any) => Array.isArray(value);

const isFile = (value: any) => value instanceof File;

const isDate = (value: any) => null != value && !isNaN(value) && 'undefined' !== typeof value.getDate;

function makeArrayKey(key: string) {
	if (key.length > 2 && key.lastIndexOf('[]') === key.length - 2) {
		return key;
	}
	return key + '[]';
}

export default function objectToFormData(obj: { [key: string]: any }, fd?: FormData, pre?: string) {
	fd = fd || new FormData();

	Object.keys(obj).forEach(prop => {
		const key = pre ? pre + '[' + prop + ']' : prop;

		if (obj[prop] === null) {
			return;
		}

		if (isObject(obj[prop]) && !isArray(obj[prop]) && !isFile(obj[prop]) && !isDate(obj[prop])) {
			objectToFormData(obj[prop], fd, key);
		} else if (isArray(obj[prop])) {
			if (!obj[prop].length) {
				fd!.append(key + '[]', '');
			}
			obj[prop].forEach((value: any) => {
				const arrayKey = makeArrayKey(key);

				if (isObject(value) && !isFile(value)) {
					objectToFormData(value, fd, arrayKey);
				} else {
					fd!.append(arrayKey, value);
					// console.log(arrayKey, value); //eslint-disable-line
				}
			});
		} else {
			fd!.append(key, obj[prop]);
			// console.log(key, obj[prop]); //eslint-disable-line
		}
	});

	return fd;
}
