import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import { AppState } from '../../redux/reducer';

/**
 * Constants
 **/
export const moduleName = 'ui/status';

export const SET_NAVBAR_OPEN = `${moduleName}/SET_NAVBAR_OPEN`;

export interface UiStatusState {
	isNavBarOpen: boolean;
}

export interface UiStatusAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		open?: boolean;
	};
}

export const initialState: UiStatusState = {
	isNavBarOpen: false,
};

/**
 * Reducer
 **/
export default (state: UiStatusState = initialState, action: UiStatusAction): UiStatusState => {
	const { type, payload } = action;

	switch (type) {
		case SET_NAVBAR_OPEN:
			return { ...state, isNavBarOpen: payload?.open || false };
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const setNavBarOpen = (open: boolean): UiStatusAction => ({
	type: SET_NAVBAR_OPEN,
	payload: { open },
});

/**
 * Selectors
 **/
export const uistatusSelector = createSelector(
	(state: AppState) => state.ui,
	ui => ui.status,
);
export const uistatusIsNavBarOpenSelector = createSelector(uistatusSelector, status => status.isNavBarOpen);
