export const rus_to_latin = (str: string): string => {
	const ru = new Map([
		['а', 'a'],
		['б', 'b'],
		['в', 'v'],
		['г', 'g'],
		['д', 'd'],
		['е', 'e'],
		['є', 'e'],
		['ё', 'e'],
		['ж', 'j'],
		['з', 'z'],
		['и', 'i'],
		['ї', 'yi'],
		['й', 'i'],
		['к', 'k'],
		['л', 'l'],
		['м', 'm'],
		['н', 'n'],
		['о', 'o'],
		['п', 'p'],
		['р', 'r'],
		['с', 's'],
		['т', 't'],
		['у', 'u'],
		['ф', 'f'],
		['х', 'h'],
		['ц', 'c'],
		['ч', 'ch'],
		['ш', 'sh'],
		['щ', 'shch'],
		['ы', 'y'],
		['э', 'e'],
		['ю', 'u'],
		['я', 'ya'],
	]);

	str = str.replace(/[ъь]+/g, '').replace(/[\s.,/\\]+/g, '_');

	return Array.from(str).reduce(
		(st, lt) =>
			st +
			(ru.get(lt) ||
				(ru.get(lt.toLowerCase()) === undefined && lt) ||
				ru.get(lt.toLowerCase())?.toUpperCase() ||
				''),
		'',
	);
};
