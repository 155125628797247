import React from 'react';
import { Link } from 'react-router-dom';

import { urlBackend } from '../../../config';
import UploadDto from 'dto/UploadDto';
import Svg from 'common/Svg';
import ExhibitDto from 'dto/ExhibitDto';

export interface ExhibitsCatalogItemProps {
	exhibit: ExhibitDto;
	onShowModal: (code: string, title: string) => void;
	onPlay: (id: number, link: string) => void;
	isPlay: boolean;
}

const ExhibitsCatalogItem: React.FC<ExhibitsCatalogItemProps> = ({ exhibit, onShowModal, onPlay, isPlay }) => {
	const { id, museumId, title, images, mp3, code, rating, likes_count, comments_count } = exhibit;

	const handlePlayMp3 = () => {
		onPlay(id!, `${urlBackend}/upload/${(mp3 as UploadDto).path}`);
	};

	const onOpenModal = () => {
		onShowModal(code || '', title || '');
	};

	return (
		<tr>
			<td>{title}</td>
			<td className="big images">
				{(images || []).map(item => (
					<label className="image" key={item.id!}>
						<img src={`${urlBackend}/upload/${item.image!.path!}`} />
					</label>
				))}
			</td>
			<td>
				<div className="table__bar">
					Рейтинг: {rating}
					<br />
					Лайков: {likes_count || 0}
					<br />
					Комментариев: {comments_count || 0}
					<br />
				</div>
			</td>
			<td>
				<div className="table__bar">
					{mp3 && (
						<a onClick={handlePlayMp3} className="table__btn">
							{isPlay ? <Svg name="stop" /> : <Svg name="play" />}
						</a>
					)}
				</div>
			</td>
			<td>
				<div className="table__bar">
					<a onClick={onOpenModal} className="table__btn">
						показать
					</a>
				</div>
			</td>
			<td>
				<div className="table__bar">
					<Link to={`/museum/exhibit/${museumId}/${id}`} className="table__btn">
						<Svg name="edit" />
					</Link>
				</div>
			</td>
		</tr>
	);
};

export default ExhibitsCatalogItem;
