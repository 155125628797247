import axios from 'axios';
import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../api';
import history from '../redux/history';
import { setPreloader } from './ui/preloaders';
import UserDto from '../dto/UserDto';
import { AppDispatch, AppState } from '../redux/reducer';

export const moduleName = 'auth';

export const FETCH_USER = `${moduleName}/FETCH_USER`;
export const FETCH_TOKEN = `${moduleName}/FETCH_TOKEN`;
export const SET_ERROR = `${moduleName}/SET_ERROR`;

export interface AuthState {
	isAuthorized: boolean;
	user: UserDto | null;
	token: string | null;
	error: string | null;
}

export interface AuthAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		isAuthorized?: boolean;
		user?: UserDto | null;
		token?: string | null;
		error?: string | null;
	};
}

export const initialState: AuthState = {
	isAuthorized: false,
	user: null,
	token: null,
	error: null,
};

export default (state: AuthState = initialState, action: AuthAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH_USER:
			return { ...state, user: payload?.user || null, isAuthorized: payload?.isAuthorized || false };
		case FETCH_TOKEN:
			return { ...state, token: payload?.token || null };
		case SET_ERROR:
			return { ...state, error: payload?.error || null };
		default:
			return { ...state };
	}
};

export const fetchUser = (user: UserDto | null, isAuthorized: boolean) => ({
	type: FETCH_USER,
	payload: { user, isAuthorized },
});

export const fetchToken = (token: string | null) => ({
	type: FETCH_TOKEN,
	payload: { token },
});

export const setError = (error: string | null) => ({
	type: SET_ERROR,
	payload: { error },
});

export const login = (email: string, password: string) => (dispatch: AppDispatch) => {
	dispatch(setError(null));
	let isError = false;
	if (!email || email.trim().length <= 0 || !password || password.trim().length <= 0) {
		isError = true;
		dispatch(setError('Введите логин и пароль'));
	}
	if (!isError) {
		dispatch(setPreloader(true));
		api.auth
			.login(email, password)
			.then(res => {
				if (res.success) {
					dispatch(fetchToken(res.data!.token));
					axios.defaults.headers.common.Authorization = res.data!.token;
					dispatch(fetchUser(res.data!.user, true));
					history.push('/');
				} else {
					dispatch(setError(res.error?.message || 'Ошибка авторизации'));
				}
				dispatch(setPreloader(false));
			})
			.catch(exc => {
				// eslint-disable-next-line no-console
				console.error(exc);
				dispatch(setPreloader(false));
				dispatch(setError('Ошибка авторизации'));
			});
	}
};

export const logout = () => (dispatch: AppDispatch) => {
	dispatch(fetchUser(null, false));
	dispatch(fetchToken(null));
	delete axios.defaults.headers.common.Authorization;
	history.push('/');
	window.location.reload();
};

export const authSelector = (state: AppState) => state.auth;
export const authUserSelector = createSelector(authSelector, auth => auth.user);
export const authIsAuthorizedSelector = createSelector(authSelector, auth => auth.isAuthorized);
export const authErrorSelector = createSelector(authSelector, auth => auth.error);
