import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './comments-edit.scss';
import { dataCommentsListSelector, getAllComments } from 'ducks/data/comments';
import CommentsPages from 'components/CommentsPage/CommentsPages';
import CommentItem from 'components/CommentsPage/CommentItem';

const CommentsPage: React.FC = () => {
	const dispatch = useDispatch();
	const list = useSelector(dataCommentsListSelector, shallowEqual);

	useEffect(() => {
		dispatch(getAllComments());
	}, []);

	if (!list || list.length <= 0) return null;

	return (
		<div className="comments-edit">
			<legend className="legend sec-title">Комментарии</legend>
			<CommentsPages />
			<div className="comments-list fieldset__row">
				<div className="fieldset__col">
					{list.map(item => (
						<CommentItem key={item.id} comment={item} />
					))}
				</div>
			</div>
		</div>
	);
};

export default CommentsPage;
