import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CityCatalog } from 'components/CityPage/CityCatalog/CityCatalog';
import CityEdit from 'components/CityPage/CityEdit/CityEdit';

export const CityPage: React.FC = () => {
	return (
		<div className="content">
			<Switch>
				<Route exact path="/city" component={CityCatalog} />
				<Route exact path="/city/edit/:id" component={CityEdit} />
			</Switch>
		</div>
	);
};
