import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { YMaps, Map, ZoomControl, Placemark } from 'react-yandex-maps';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useStore } from 'effector-react';

import './museum-edit.scss';
import Svg from 'common/Svg';
import {
	editMuseumEditSelector,
	editMuseumErrorsSelector,
	editMuseumPreloaderSelector,
	getMuseum,
	removeMuseum,
	saveMuseum,
	setErrorField,
	setField,
} from 'ducks/edit/museum';
import { urlBackend } from '../../../config';
import UploadDto from 'dto/UploadDto';
import Preloader from 'common/Preloader/Preloader';
import { $cityesList, loadCityes } from 'models/city';
import StyledSuggest from 'common/StyledSuggest/StyledSuggest';
import { Option } from 'common/StyledSelect/StyledSelect';
import { CityDto } from 'dto/CityDto';
import { MuseumsTags, museumsTagsList } from 'dto/MuseumDto';

const MuseumEdit: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const [pageTitle, setPageTile] = useState('Добавление музея');
	const [icoFile, setIcoFile] = useState<string | null>(null);
	const cityList = useStore($cityesList);
	const museum = useSelector(editMuseumEditSelector, shallowEqual);
	const errors = useSelector(editMuseumErrorsSelector, shallowEqual);
	const preloader = useSelector(editMuseumPreloaderSelector, shallowEqual);

	useEffect(() => {
		if (id === 'add') {
			setPageTile('Добавление музея');
			dispatch(getMuseum());
		} else {
			setPageTile('Редактирование музея');
			dispatch(getMuseum(parseInt(id)));
		}
	}, [id]);

	const { title, description, www, phone, address, lon, lat, ico, city, tags } = museum;

	const handleChangePlace = (evnt: any) => {
		const [nlat, nlon] = evnt.get('coords');
		dispatch(setField('lat', nlat));
		dispatch(setField('lon', nlon));
	};

	const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setErrorField('title', null));
		dispatch(setField('title', event.target.value));
	};

	const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		dispatch(setField('description', event.target.value));
	};

	const handleChangeWWW = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('www', event.target.value));
	};

	const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('phone', event.target.value));
	};

	const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('address', event.target.value));
	};

	const handleChangeIcon = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			dispatch(setErrorField('ico', null));
			const reader = new FileReader();
			reader.onloadend = () => {
				const image = new Image();
				image.onload = () => {
					if (image.naturalWidth !== image.naturalHeight) {
						dispatch(setErrorField('ico', 'Картинка не квадратная!'));
						setIcoFile(null);
						dispatch(setField('ico', null));
					}
				};
				image.src = reader.result as string;
				setIcoFile(reader.result as string);
			};
			dispatch(setField('ico', event.target!.files![0]));
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const handleChangeCityText = (text: string) => {
		dispatch(setField('cityId', null));
		dispatch(setField('city', null));
		loadCityes(text || '');
	};

	const handleDropCity = (text: string | null) => {
		loadCityes(text || '');
	};

	const handleChangeCity = (option: Option) => {
		dispatch(setField('cityId', (option as CityDto).id));
		dispatch(setField('city', option as CityDto));
	};

	const renderCity = (option: any, index: number) => {
		return <div className="drop-group__txt">{(option as CityDto).title}</div>;
	};

	const handleChangeTag = (key: string) => {
		const ntags = tags ? [...tags] : [];
		if (!ntags.includes(key as keyof MuseumsTags)) {
			ntags.push(key as keyof MuseumsTags);
		} else {
			ntags.splice(ntags.indexOf(key as keyof MuseumsTags), 1);
		}
		dispatch(setField('tags', ntags));
	};

	const handleDelete = () => {
		if (confirm('Вы уверены?')) {
			dispatch(removeMuseum(parseInt(id)));
		}
	};

	const handleSave = () => {
		dispatch(saveMuseum());
	};

	const renderIcon = () => {
		if (icoFile) {
			return <img src={icoFile} />;
		} else if (ico) {
			return <img src={`${urlBackend}/upload/${(ico as UploadDto).path}`} />;
		}
		return <Svg name="arrow-up" className="museum-edit__icon-up" width="42" height="42" />;
	};

	return (
		<YMaps>
			<div className="museum-edit">
				<legend className="legend sec-title">{pageTitle}</legend>
				<div className="fieldset__row">
					<div className="fieldset__col">
						<fieldset className="fieldset">
							<label className="label">
								<legend>Название музея:</legend>
								<input
									type="text"
									className="input"
									placeholder="Название"
									value={title}
									onChange={handleChangeTitle}
								/>
								{errors.title && <div className="error">{errors.title}</div>}
							</label>
						</fieldset>
						<fieldset className="fieldset">
							<label className="label">
								<legend>Описание музея до 5000 символов:</legend>
								<textarea
									className="textarea"
									placeholder="Описание"
									value={description || ''}
									onChange={handleChangeDescription}
								/>
							</label>
						</fieldset>
						<fieldset className="fieldset">
							<label className="label">
								<legend>Ссылка на сайт:</legend>
								<input
									type="text"
									className="input"
									placeholder="https://"
									value={www || ''}
									onChange={handleChangeWWW}
								/>
							</label>
						</fieldset>
						<fieldset className="fieldset">
							<label className="label">
								<legend>Номер телефона:</legend>
								<input
									type="text"
									className="input"
									placeholder="+7"
									value={phone || ''}
									onChange={handleChangePhone}
								/>
							</label>
						</fieldset>
						<fieldset className="fieldset">
							<label className="label">
								<legend>Город:</legend>
								<StyledSuggest
									value={city?.title ?? ''}
									list={cityList}
									onChange={handleChangeCity}
									onDropDown={handleDropCity}
									onChangeText={handleChangeCityText}
									renderItem={renderCity}
								/>
								{errors.cityId && <div className="error">{errors.cityId}</div>}
							</label>
						</fieldset>
						<fieldset className="fieldset">
							<label className="label">
								<legend>Адрес:</legend>
								<input
									type="text"
									className="input"
									placeholder="Город, улица, дом"
									value={address || ''}
									onChange={handleChangeAddress}
								/>
							</label>
						</fieldset>
					</div>
					<div className="fieldset__col">
						<label className="label">
							<legend>Логотип (квадратный!):</legend>
							<div className="file-info small">
								{renderIcon()}
								<input type="file" accept=".jpg,.jpeg,.png" onChange={handleChangeIcon} />
								<span>Загрузить</span>
							</div>
							{errors.ico && <div className="error">{errors.ico}</div>}
						</label>
						<label className="label">
							<legend>Координаты:</legend>
							<Map
								width="100%"
								height={400}
								defaultState={{
									center: [lat ? parseFloat(lat) : 55.75, lon ? parseFloat(lon) : 37.57],
									zoom: 9,
								}}
								onClick={handleChangePlace}
							>
								<ZoomControl options={{ float: 'right' }} />
								{/*<SearchControl options={{ float: 'right' }} />*/}
								<Placemark
									defaultGeometry={[lat ? parseFloat(lat) : 55.75, lon ? parseFloat(lon) : 37.57]}
									geometry={[lat ? parseFloat(lat) : 55.75, lon ? parseFloat(lon) : 37.57]}
								/>
							</Map>
						</label>
					</div>
				</div>
				<div className="fieldset__row">
					<div className="fieldset__col">
						<fieldset className="fieldset">
							<label className="label">
								<legend>Тэги:</legend>
								{Object.keys(museumsTagsList).map(key => (
									<button
										key={key}
										className={`tag ${tags?.includes(key as keyof MuseumsTags) ? 'active' : ''}`}
										onClick={() => handleChangeTag(key)}
									>
										{museumsTagsList[key as keyof MuseumsTags] || ''}
									</button>
								))}
							</label>
						</fieldset>
					</div>
				</div>
				{preloader ? (
					<Preloader />
				) : (
					<div className="museum-edit__buttons">
						{id !== 'add' && (
							<button className="btn delete" onClick={handleDelete}>
								Удалить
							</button>
						)}
						<button className="btn submit" onClick={handleSave}>
							Сохранить
						</button>
					</div>
				)}
			</div>
		</YMaps>
	);
};

export default MuseumEdit;
