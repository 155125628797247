import { createEffect, createEvent, createStore } from 'effector';

import api from 'api/index';
import history from '../redux/history';
import { CityDto } from 'dto/CityDto';

const initialCity: CityDto = {
	title: '',
	lat: null,
	lon: null,
};

export const loadCityes = createEffect<string, CityDto[]>(async query => {
	return await api.city.list(query).then(res => {
		if (res.success && res.data) return res.data!;
		throw Error(res.error?.message);
	});
});

export const getCity = createEffect<number, CityDto>(async id => {
	return await api.city.get(id).then(res => {
		if (res.success && res.data) return res.data!;
		throw Error(res.error?.message);
	});
});

export const clearCity = createEvent('clear city');

export const changeCityField = createEvent<{ key: keyof CityDto; value: any }>('change City field');

export const saveCity = createEffect<CityDto, void>(async city => {
	if (city.id) {
		api.city.update(city.id, city).then(() => {
			history.push(`/city`);
		});
	} else {
		api.city.save(city).then(() => {
			history.push(`/city`);
		});
	}
});

export const removeCity = createEffect<number, void>(async id => {
	api.city.remove(id).then(() => {
		history.push(`/city`);
	});
});

export const $loadingCityes = createStore<boolean>(false)
	.on(loadCityes, () => true)
	.on(loadCityes.finally, () => false)
	.on(getCity, () => true)
	.on(getCity.finally, () => false)
	.on(saveCity, () => true)
	.on(saveCity.finally, () => false)
	.on(removeCity, () => true)
	.on(removeCity.finally, () => false);

export const $cityesList = createStore<CityDto[]>([]).on(loadCityes.doneData, (__, data) => data);

export const $city = createStore<CityDto>(initialCity)
	.on(getCity.doneData, (__, data) => data)
	.on(clearCity, () => initialCity)
	.on(changeCityField, (old, { key, value }) => ({ ...old, [key]: value }));
