import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import useRequireAuth from 'hooks/useRequireAuth';
import Preloader from 'common/Preloader/Preloader';
import Header from 'shared/Header/Header';
import NavBar from 'shared/NavBar/NavBar';
import DashboardPage from 'components/DashboardPage/DashboardPage';
import MuseumPage from 'components/MuseumPage/MuseumPage';
import UsersPage from 'components/UsersPage/UsersPage';
import CommentsPage from 'components/CommentsPage/CommentsPage';
import { CityPage } from 'components/CityPage/CityPage';

const MainPage: React.FC = () => {
	const isAuth = useRequireAuth();
	if (!isAuth) return <Preloader />;
	return (
		<Fragment>
			<Header />
			<NavBar />
			<main className="main">
				<Switch>
					<Route path="/dashboard" component={DashboardPage} />
					<Route path="/museum" component={MuseumPage} />
					<Route path="/city" component={CityPage} />
					<Route path="/users" component={UsersPage} />
					<Route path="/comments" component={CommentsPage} />
				</Switch>
			</main>
		</Fragment>
	);
};

export default MainPage;
