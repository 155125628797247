import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import MuseumDto from 'dto/MuseumDto';
import { push } from 'connected-react-router';
import { getMuseumsList } from 'ducks/data/museums';
import UploadDto from 'dto/UploadDto';

export const moduleName = 'edit/museum';

export const FETCH = `${moduleName}/FETCH`;
export const CLEAR_ERRORS = `${moduleName}/CLEAR_ERRORS`;
export const SET_FIELD = `${moduleName}/SET_FIELD`;
export const SET_ERROR_FIELD = `${moduleName}/SET_ERROR_FIELD`;
export const SET_PRELOADER = `${moduleName}/SET_PRELOADER`;

export const initialMuseum: MuseumDto = {
	ico: null,
	title: '',
	description: null,
	www: null,
	phone: null,
	address: null,
	lat: '55.75',
	lon: '37.57',
};

export interface EditMuseumErrorsState {
	title: string | null;
	cityId: string | null;
	ico: string | null;
}

export const initialErrors: EditMuseumErrorsState = {
	title: null,
	cityId: null,
	ico: null,
};

export interface EditMuseumState {
	museum: MuseumDto;
	errors: EditMuseumErrorsState;
	preloader: boolean;
}

export const initialState: EditMuseumState = {
	museum: initialMuseum,
	errors: initialErrors,
	preloader: false,
};

export interface EditMuseumAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		museum?: MuseumDto;
		field?: keyof MuseumDto;
		errorField?: keyof EditMuseumErrorsState;
		value?: any;
		preloader?: boolean;
	};
}

export default (state = initialState, action: EditMuseumAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, museum: payload?.museum || initialMuseum, errors: initialErrors };
		case CLEAR_ERRORS:
			return { ...state, errors: initialErrors };
		case SET_FIELD:
			return { ...state, museum: { ...state.museum, [payload!.field!]: payload?.value || null } };
		case SET_ERROR_FIELD:
			return { ...state, errors: { ...state.errors, [payload!.errorField!]: payload?.value || null } };
		case SET_PRELOADER:
			return { ...state, preloader: payload?.preloader || false };
		default:
			return { ...state };
	}
};

export const fetch = (museum: MuseumDto) => ({
	type: FETCH,
	payload: { museum },
});

export const clearErrors = () => ({
	type: CLEAR_ERRORS,
});

export const setPreloader = (preloader: boolean) => ({
	type: SET_PRELOADER,
	payload: { preloader },
});

export const setField = (field: keyof MuseumDto, value: any) => ({
	type: SET_FIELD,
	payload: { field, value },
});

export const setErrorField = (errorField: keyof EditMuseumErrorsState, value: any) => ({
	type: SET_ERROR_FIELD,
	payload: { errorField, value },
});

export const getMuseum = (id?: number) => (dispatch: AppDispatch) => {
	if (!id) dispatch(fetch(initialMuseum));
	else
		api.museum.get(id).then(res => {
			if (res.success && res.data) {
				dispatch(fetch(res.data));
			}
		});
};

export const getMuseumShort = (id?: number) => (dispatch: AppDispatch) => {
	if (!id) dispatch(fetch(initialMuseum));
	else
		api.museum.short(id).then(res => {
			if (res.success && res.data) {
				dispatch(fetch(res.data));
			}
		});
};

export const removeMuseum = (id: number) => (dispatch: AppDispatch) => {
	api.museum.remove(id).then(() => {
		dispatch(getMuseumsList());
		dispatch(push('/museum'));
	});
};

export const saveMuseum = () => (dispatch: AppDispatch, getState: () => AppState) => {
	const museum = editMuseumEditSelector(getState());
	dispatch(clearErrors());
	let isErrors = false;
	if (!museum.title || museum.title.trim().length <= 0) {
		isErrors = true;
		dispatch(setErrorField('title', 'Поле не заполнено'));
	}
	if (!museum.cityId) {
		isErrors = true;
		dispatch(setErrorField('cityId', 'Поле не заполнено'));
	}
	if (!isErrors) {
		dispatch(setPreloader(true));
		let ico: File | null = null;
		if (museum.ico && !(museum.ico as UploadDto).id) {
			ico = museum.ico as File;
			delete museum.ico;
		}
		if (museum.id) {
			api.museum.update(museum.id, museum, ico).then(() => {
				dispatch(getMuseumsList());
				dispatch(push('/museum'));
				dispatch(setPreloader(false));
			});
		} else {
			api.museum.create(museum, ico).then(() => {
				dispatch(getMuseumsList());
				dispatch(push('/museum'));
				dispatch(setPreloader(false));
			});
		}
	}
};

const editSelector = (state: AppState) => state.edit;
export const editMuseumSelector = createSelector(editSelector, edit => edit.museum);
export const editMuseumEditSelector = createSelector(editMuseumSelector, museum => museum.museum);
export const editMuseumErrorsSelector = createSelector(editMuseumSelector, museum => museum.errors);
export const editMuseumPreloaderSelector = createSelector(editMuseumSelector, museum => museum.preloader);
