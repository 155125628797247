import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './nav-bar__sub.scss';
import Svg from 'common/Svg';
import { setNavBarOpen } from 'ducks/ui/status';
import SubMenuItem from 'shared/NavBar/MenuItem/SubMenuItem';
import { MenuItemType } from 'hooks/useMenu';

export interface MenuItemProps {
	item: MenuItemType;
}

const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [isOpen, setOpen] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		if (item.open) {
			setOpen(true);
		}
	}, [item]);

	const handleOpen = (event: React.MouseEvent) => {
		if (!item.link) {
			setOpen(!isOpen);
			event.preventDefault();
		} else {
			if (item.onClick) {
				item.onClick();
			}
			dispatch(setNavBarOpen(false));
		}
	};

	const isActive = () => {
		// if (item.submenu && item.submenu.length > 0) return false;
		if (pathname === item.link) {
			if (item.active) return item.active();
			return true;
		}
		return false;
	};

	return (
		<li>
			<Link
				to={item.link || '/'}
				className={`nav-bar__item ${isOpen ? 'open' : ''} ${isActive() ? 'active' : ''}`}
				onClick={handleOpen}
			>
				<i className="nav-bar__item-icn">{item.ico && <Svg name={item.ico} />}</i>
				<span>{item.title || '????'}</span>
				{item.submenu && item.submenu.length > 0 && (
					<i className="nav-bar__item-arrow">
						<Svg name="chevrone-drop-down" />
					</i>
				)}
			</Link>
			{item.submenu && item.submenu.length > 0 && (
				<CSSTransition classNames="nav-bar__sub" in={isOpen} timeout={300}>
					<ul className="nav-bar__sub">
						{item.submenu.map((subitem, index) => (
							<SubMenuItem key={`${index}_${subitem.link}`} subitem={subitem} />
						))}
					</ul>
				</CSSTransition>
			)}
		</li>
	);
};

export default MenuItem;
