import React from 'react';
import { Link } from 'react-router-dom';

import './dashboard-page.scss';
import Svg from 'common/Svg';

const DashboardPage: React.FC = () => {
	return (
		<div className="content">
			<ul className="dashboard-page">
				<li className="dashboard-page__item">
					<Link to="/users" className="dashboard-page__link">
						<div className="dashboard-page__link-pic">
							<Svg name="team" />
						</div>
						<div className="dashboard-page__link-txt">Пользователи</div>
					</Link>
				</li>
				<li className="dashboard-page__item">
					<Link to="/city" className="dashboard-page__link">
						<div className="dashboard-page__link-pic">
							<Svg name="city" />
						</div>
						<div className="dashboard-page__link-txt">Города</div>
					</Link>
				</li>
				<li className="dashboard-page__item">
					<Link to="/museum" className="dashboard-page__link">
						<div className="dashboard-page__link-pic">
							<Svg name="museum" />
						</div>
						<div className="dashboard-page__link-txt">Музеи</div>
					</Link>
				</li>
				<li className="dashboard-page__item">
					<Link to="/comments" className="dashboard-page__link">
						<div className="dashboard-page__link-pic">
							<Svg name="comments" />
						</div>
						<div className="dashboard-page__link-txt">Комментарии</div>
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default DashboardPage;
