import 'reflect-metadata';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import './assets/svgSprite/svgInsert';
import './scss/index.scss';
import history from './redux/history';
import App from 'components/App';
import ScrollToTop from 'common/ScrollToTop';
import initStore from 'api/init';

const store = initStore();

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<ScrollToTop>
				<App />
			</ScrollToTop>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('app'),
);
