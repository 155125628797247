import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import ExhibitDto from 'dto/ExhibitDto';

export const moduleName = 'data/exhibits';

export const FETCH = `${moduleName}/FETCH`;

export interface DataExhibitsState {
	list: ExhibitDto[];
}

export const initialState: DataExhibitsState = {
	list: [],
};

export interface DataExhibitsAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: ExhibitDto[];
	};
}

export default (state = initialState, action: DataExhibitsAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [] };
		default:
			return { ...state };
	}
};

export const fetch = (list: ExhibitDto[]) => ({
	type: FETCH,
	payload: { list },
});

export const getExhibitsList = (museumId: number) => (dispatch: AppDispatch) => {
	api.exhibit.list(museumId).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
	});
};

const dataSelector = (state: AppState) => state.data;
export const dataExhibitsSelector = createSelector(dataSelector, data => data.exhibits);
export const dataExhibitsListSelector = createSelector(dataExhibitsSelector, exhibits => exhibits.list);
