import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import { saveSvgAsPng } from 'save-svg-as-png';

import 'react-responsive-modal/styles.css';
import './qr-modal.scss';

export interface QRModalProps {
	value: string;
	title: string;
}

const QRModal: React.FC<QRModalProps> = ({ value, title }) => {
	const svgRef = useRef(null);

	const handlePrint = () => {
		const headstr = '<html><head><title></title></head><body>';
		const footstr = '</body>';
		const newstr = document.getElementById('printpage')!.innerHTML;
		const win = window.open('', 'Печать');
		win!.document.body.innerHTML = headstr + newstr + '<br />' + title + '<br />' + footstr;
		win!.print();
		setTimeout(() => win!.close(), 1000);
	};

	const handleSave = () => {
		saveSvgAsPng(
			document.getElementById('printpage')!.childNodes[0] as HTMLElement | SVGElement | Element,
			`${title}.png`,
		);
	};

	return (
		<div className="qr-modal">
			<div id="printpage">
				<QRCode ref={svgRef} value={value} size={400} />
				<br />
				{/*{name}*/}
			</div>
			<div className="modal_buttons">
				<button className="btn submit" onClick={handlePrint}>
					Печть
				</button>
				<button className="btn submit" onClick={handleSave}>
					PNG
				</button>
			</div>
		</div>
	);
};

export default QRModal;
