import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import UserDto from 'dto/UserDto';
import { dataCommentsListSelector, getUserComments } from 'ducks/data/comments';
import CommentItem from 'components/UsersPage/UserEdit/CommentsList/CommentItem';
import CommentsPages from 'components/UsersPage/UserEdit/CommentsList/CommentsPages';

export interface CommentsListProps {
	user: UserDto;
}

const CommentsList: React.FC<CommentsListProps> = ({ user }) => {
	const dispatch = useDispatch();
	const list = useSelector(dataCommentsListSelector, shallowEqual);

	useEffect(() => {
		if (user.id) dispatch(getUserComments(user.id));
	}, [user]);

	if (!list || list.length <= 0) return null;

	return (
		<section>
			<legend className="legend sec-title">Комментарии пользователя</legend>
			<CommentsPages userId={user.id!} />
			<div className="comments-list fieldset__row">
				<div className="fieldset__col">
					{list.map(item => (
						<CommentItem key={item.id} comment={item} userId={user.id!} />
					))}
				</div>
			</div>
		</section>
	);
};

export default CommentsList;
