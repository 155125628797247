import { AnyAction, combineReducers, Reducer } from 'redux';
import { connectRouter, LocationChangeAction, RouterState } from 'connected-react-router';
import { History } from 'history';
import { ThunkAction } from 'redux-thunk';

import ui, { UiState } from 'ducks/ui';
import auth, { AuthState } from 'ducks/auth';
import data, { DataState } from 'ducks/data';
import edit, { EditState } from 'ducks/edit';
import audio, { AudioState } from 'ducks/audio';

export interface AppState {
	router: Reducer<RouterState<History>, LocationChangeAction<History>>;
	ui: UiState;
	auth: AuthState;
	data: DataState;
	edit: EditState;
	audio: AudioState;
}

export type AppDispatch = (action: AnyAction | ThunkAction<void, AppState, null, AnyAction>) => void;

const createRootReducer = (history: History): Reducer =>
	combineReducers({
		router: connectRouter(history),
		ui,
		auth,
		data,
		edit,
		audio,
	});

export default createRootReducer;
