import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UsersCatalog from 'components/UsersPage/UsersCatalog/UsersCatalog';
import UserEdit from 'components/UsersPage/UserEdit/UserEdit';

const UsersPage: React.FC = () => {
	return (
		<div className="content">
			<Switch>
				<Route exact path="/users" component={UsersCatalog} />
				<Route exact path="/users/:id" component={UserEdit} />
			</Switch>
		</div>
	);
};

export default UsersPage;
