import { urlBackend } from '../config';

import noPhoto from './anonim.png';
import UserDto from 'dto/UserDto';
import UploadDto from 'dto/UploadDto';

export const getName = (user?: UserDto) =>
	!user ? '' : user.isGeust ? 'Гость ' + user.email : user.name ? user.name : user.email || '';

export const getAvatar = (user: UserDto) =>
	user.avatar ? `${urlBackend}/upload/${(user.avatar as UploadDto).path}` : noPhoto;
