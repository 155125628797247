import React, { useEffect } from 'react';

import './city-catalog.scss';
import Svg from 'common/Svg';
import { useStore } from 'effector-react';
import { $cityesList, $loadingCityes, loadCityes } from 'models/city';
import Preloader from 'common/Preloader/Preloader';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { CityCatalogItem } from 'components/CityPage/CityCatalog/CityCatalogItem';

export const CityCatalog: React.FC = () => {
	const dispatch = useDispatch();
	const list = useStore($cityesList);
	const loading = useStore($loadingCityes);

	useEffect(() => {
		loadCityes('');
	}, []);

	const handleAdd = () => {
		dispatch(push('/city/edit/add'));
	};

	return (
		<div className="city-catalog">
			<div className="city-catalog__filter">
				<legend className="legend sec-title">Список городов</legend>
				<button className="btn submit" onClick={handleAdd}>
					<Svg name="plus" />
					Добавить
				</button>
			</div>
			{loading ? (
				<Preloader />
			) : (
				<div className="table">
					<div className="table__wrap">
						<table>
							<thead>
								<tr>
									<th className="big">
										<div className="table__head">Название</div>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{list.map(item => (
									<CityCatalogItem city={item} key={item.id} />
								))}
							</tbody>
						</table>
					</div>
				</div>
			)}
		</div>
	);
};
