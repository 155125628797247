import axios, { AxiosResponse } from 'axios';

import flatten from 'utils/objectToFormData';
import { APIResponse, ResponseAuth } from '../types/ResponseType';
import MuseumDto from 'dto/MuseumDto';
import UserDto from 'dto/UserDto';
import ExhibitDto from 'dto/ExhibitDto';
import CommentDto from 'dto/CommentDto';
import { CityDto } from 'dto/CityDto';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendData = (res: AxiosResponse): APIResponse<any> => res.data;

export default {
	auth: {
		login: (email: string, password: string): Promise<APIResponse<ResponseAuth>> =>
			axios.post('/auth', { email, password }).then(sendData),
	},
	museum: {
		list: (): Promise<APIResponse<MuseumDto[]>> => axios.get('/museum').then(sendData),
		get: (id: number): Promise<APIResponse<MuseumDto>> => axios.get(`/museum/${id}`).then(sendData),
		short: (id: number): Promise<APIResponse<MuseumDto>> => axios.get(`/museum/short/${id}`).then(sendData),
		remove: (id: number): Promise<APIResponse<any>> => axios.delete(`/museum/${id}`).then(sendData),
		create: (museum: MuseumDto, ico: File | null) =>
			axios.put('/museum', flatten({ museum: JSON.stringify(museum), ico })).then(sendData),
		update: (id: number, museum: MuseumDto, ico: File | null) =>
			axios.post(`/museum/${id}`, flatten({ museum: JSON.stringify(museum), ico })).then(sendData),
		stat: () =>
			axios({
				url: `/museum/stat`,
				method: 'GET',
				responseType: 'blob',
			}),
	},
	exhibit: {
		list: (museumId: number): Promise<APIResponse<ExhibitDto[]>> =>
			axios.get(`/exhibit/list/${museumId}`).then(sendData),
		get: (id: number): Promise<APIResponse<ExhibitDto>> => axios.get(`/exhibit/${id}`).then(sendData),
		remove: (id: number): Promise<APIResponse<any>> => axios.delete(`/exhibit/${id}`).then(sendData),
		create: (exhibit: ExhibitDto, mp3: File | null, images: File[]) =>
			axios.put('/exhibit', flatten({ exhibit: JSON.stringify(exhibit), mp3, images })).then(sendData),
		update: (id: number, exhibit: ExhibitDto, mp3: File | null, images: File[]) =>
			axios.post(`/exhibit/${id}`, flatten({ exhibit: JSON.stringify(exhibit), mp3, images })).then(sendData),
	},
	user: {
		list: (isadmin: boolean, isgeust: boolean, isreg: boolean): Promise<APIResponse<UserDto[]>> =>
			axios.get(`/user?isadmin=${isadmin}&isgeust=${isgeust}&isreg=${isreg}`).then(sendData),
		get: (id: number): Promise<APIResponse<UserDto>> => axios.get(`/user/${id}`).then(sendData),
		remove: (id: number): Promise<APIResponse<any>> => axios.delete(`/user/${id}`).then(sendData),
		create: (user: UserDto, avatar: File | null) =>
			axios.put('/user', flatten({ user: JSON.stringify(user), avatar })).then(sendData),
		update: (id: number, user: UserDto, avatar: File | null) =>
			axios.post(`/user/${id}`, flatten({ user: JSON.stringify(user), avatar })).then(sendData),
	},
	comment: {
		all: (offset: number): Promise<APIResponse<{ total: { count: number }; list: CommentDto[] }>> =>
			axios.get(`/comment?offset=${offset}`).then(sendData),
		user: (id: number, offset: number): Promise<APIResponse<{ total: { count: number }; list: CommentDto[] }>> =>
			axios.get(`/comment/user/${id}?offset=${offset}`).then(sendData),
		exhibit: (id: number, offset: number): Promise<APIResponse<{ total: { count: number }; list: CommentDto[] }>> =>
			axios.get(`/comment/exhibit/${id}?offset=${offset}`).then(sendData),
		remove: (id: number): Promise<APIResponse<any>> => axios.delete(`/comment/${id}`).then(sendData),
	},
	city: {
		list: (query: string): Promise<APIResponse<CityDto[]>> => axios.get(`/city?query=${query}`).then(sendData),
		get: (id: number): Promise<APIResponse<CityDto>> => axios.get(`/city/${id}`).then(sendData),
		save: (city: CityDto): Promise<APIResponse<CityDto>> => axios.put('/city', { city }).then(sendData),
		update: (id: number, city: CityDto): Promise<APIResponse<CityDto>> =>
			axios.post(`/city/${id}`, { city }).then(sendData),
		remove: (id: number): Promise<APIResponse<any>> => axios.delete(`/city/${id}`).then(sendData),
	},
};
