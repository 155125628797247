import { combineReducers } from 'redux';

import museum, { EditMuseumState, initialState as EditMuseumInitialState } from './museum';
import user, { EditUserState, initialState as EditUserInitialState } from './user';
import exhibit, { EditExhibitState, initialState as EditExhibitInitialState } from './exhibit';

export interface EditState {
	museum: EditMuseumState;
	user: EditUserState;
	exhibit: EditExhibitState;
}
export const editSelector = (state: { edit: EditState }): EditState => state.edit;

export const initialState: EditState = {
	museum: EditMuseumInitialState,
	user: EditUserInitialState,
	exhibit: EditExhibitInitialState,
};

export default combineReducers<EditState>({
	museum,
	user,
	exhibit,
});
