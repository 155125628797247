import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { authIsAuthorizedSelector } from 'ducks/auth';
import useRouter from 'hooks/useRouter';

export default (redirectUrl = '/auth') => {
	const router = useRouter();
	const isAuth = useSelector(authIsAuthorizedSelector, shallowEqual);

	useEffect(() => {
		if (!isAuth) {
			router.push(redirectUrl);
		}
	}, [isAuth]);

	return isAuth;
};
