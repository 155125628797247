import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './nav-bar.scss';
import { setNavBarOpen, uistatusIsNavBarOpenSelector } from 'ducks/ui/status';
import MenuItem from 'shared/NavBar/MenuItem/MenuItem';
import useMenu from 'hooks/useMenu';

const NavBar: React.FC = () => {
	const dispatch = useDispatch();
	const menu = useMenu();
	const isNavOpen = useSelector(uistatusIsNavBarOpenSelector, shallowEqual);

	const handleOpenNavBar = () => {
		dispatch(setNavBarOpen(!isNavOpen));
	};

	return (
		<>
			<nav className={`nav-bar ${isNavOpen ? 'open' : ''}`}>
				<ul>
					{menu.map((item, index) => (
						<MenuItem key={`${index}_${item.link}`} item={item} />
					))}
				</ul>
			</nav>
			<div className={`nav-bar__overlay ${isNavOpen ? 'open' : ''}`} onClick={handleOpenNavBar}></div>
		</>
	);
};

export default NavBar;
