import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import MuseumDto from 'dto/MuseumDto';

export const moduleName = 'data/museums';

export const FETCH = `${moduleName}/FETCH`;

export interface DataMuseumsState {
	list: MuseumDto[];
}

export const initialState: DataMuseumsState = {
	list: [],
};

export interface DataMuseumsAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: MuseumDto[];
	};
}

export default (state = initialState, action: DataMuseumsAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [] };
		default:
			return { ...state };
	}
};

export const fetch = (list: MuseumDto[]) => ({
	type: FETCH,
	payload: { list },
});

export const getMuseumsList = () => (dispatch: AppDispatch) => {
	api.museum.list().then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
	});
};

export const getStat = () => (dispatch: AppDispatch) => {
	api.museum
		.stat()
		.then(response => {
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				}),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `stat.xlsx`);
			document.body.appendChild(link);
			// dispatch(setProgress(0));
			link.click();
		})
		.catch(exc => {
			// eslint-disable-next-line no-console
			console.log('ERROR', exc);
			// dispatch(setProgress(0));
		});
};

const dataSelector = (state: AppState) => state.data;
export const dataMuseumsSelector = createSelector(dataSelector, data => data.museums);
export const dataMuseumsListSelector = createSelector(dataMuseumsSelector, museums => museums.list);
