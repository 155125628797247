import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import QRCode from 'react-qr-code';
import QRReader from 'qrcode-reader';
import Modal from 'react-responsive-modal';

import './exhibit-edit.scss';
import 'react-responsive-modal/styles.css';
import { editMuseumEditSelector, getMuseum } from 'ducks/edit/museum';
import {
	editExhibitEditSelector,
	editExhibitErrorsSelector,
	editExhibitPreloaderSelector,
	getExhibit,
	removeExhibit,
	saveExhibit,
	setField,
} from 'ducks/edit/exhibit';
import { urlBackend } from '../../../config';
import UploadDto from 'dto/UploadDto';
import Svg from 'common/Svg';
import QRModal from 'common/QRModal/QRModal';
import Preloader from 'common/Preloader/Preloader';
import { rus_to_latin } from 'utils/string';
import CommentsList from 'components/MuseumPage/ExhibitEdit/CommentsList/CommentsList';

const ExhibitEdit: React.FC = () => {
	const addRef = useRef<HTMLInputElement>(null);
	const { id, museumId } = useParams<{ id: string; museumId: string }>();
	const dispatch = useDispatch();
	const [modalOpen, setModalOpen] = useState(false);
	const [pageTitle, setPageTile] = useState('Добавление музея');
	const [newImages, setNewImages] = useState<{ file: File; preview: string }[]>([]);
	const museum = useSelector(editMuseumEditSelector, shallowEqual);
	const exhibit = useSelector(editExhibitEditSelector, shallowEqual);
	const errors = useSelector(editExhibitErrorsSelector, shallowEqual);
	const preloader = useSelector(editExhibitPreloaderSelector, shallowEqual);

	useEffect(() => {
		if (museumId && !isNaN(parseInt(museumId))) {
			dispatch(getMuseum(parseInt(museumId)));
		}
		if (museum) {
			if (id === 'add') {
				setPageTile(`Добавление экспоната`);
				dispatch(getExhibit(parseInt(museumId)));
			} else {
				setPageTile(`Редактирование экспоната`);
				dispatch(getExhibit(parseInt(museumId), parseInt(id)));
			}
		}
	}, [id, museumId]);

	const { title, description, mp3, code, nfc, images } = exhibit;

	const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('title', event.target.value));
	};

	const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		dispatch(setField('description', event.target.value));
	};

	const handleChangeMp3 = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('mp3', event.target!.files![0]));
	};

	const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('code', event.target.value.trim().toLowerCase()));
	};

	const handleChangeNFC = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setField('nfc', event.target.value));
	};

	const handleRenewCode = () => {
		dispatch(setField('code', uuid().trim().toLowerCase()));
	};

	const handleUploadQR = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const qr = new QRReader();
				qr.callback = (err, value) => {
					if (!err && value && value.result) {
						dispatch(setField('code', value.result));
					}
				};
				qr.decode(reader.result as string);
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const onOpenModal = () => {
		setModalOpen(true);
	};

	const onCloseModal = () => {
		setModalOpen(false);
	};

	const handleDelete = () => {
		if (confirm('Вы уверены?')) {
			dispatch(removeExhibit(parseInt(museumId), parseInt(id)));
		}
	};

	const handleSave = () => {
		dispatch(
			saveExhibit(
				parseInt(museumId),
				newImages.map(item => item.file),
			),
		);
	};

	const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onloadend = () => {
				const preview = reader.result as string;
				setNewImages(old => [...old, { file, preview }]);
				addRef.current!.value = '';
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const handleDeleteImage = (index: number) => {
		setNewImages(old => {
			const res = [...old];
			res.splice(index, 1);
			return res;
		});
	};

	const handleDeleteOldImage = (imgId: number) => {
		const res = [];
		for (const img of images || []) {
			if (img.id !== imgId) res.push(img);
		}
		dispatch(setField('images', res));
	};

	const renderMp3 = () => {
		if (mp3) {
			if ((mp3 as UploadDto).id) {
				return <a href={`${urlBackend}/upload/${(mp3 as UploadDto).path}`}>{(mp3 as UploadDto).path}</a>;
			}
			return <span>{(mp3 as File).name}</span>;
		}
		return <Svg name="arrow-up" className="exhibit-edit__icon-up" width="42" height="42" />;
	};

	return (
		<div className="exhibit-edit">
			<legend className="legend sec-title">{`${museum.title}: ${pageTitle}`}</legend>
			<div className="fieldset__row">
				<div className="fieldset__col">
					<fieldset className="fieldset">
						<label className="label">
							<legend>Название экспоната:</legend>
							<input
								type="text"
								className="input"
								placeholder="Название"
								value={title}
								onChange={handleChangeTitle}
							/>
							{errors.title && <div className="error">{errors.title}</div>}
						</label>
					</fieldset>
					<fieldset className="fieldset">
						<label className="label">
							<legend>Код экспоната:</legend>
							<div className="exhibit-edit__code">
								<input
									type="text"
									className="input"
									placeholder="rfid && qrcode"
									value={code}
									onChange={handleChangeCode}
								/>
								<a title="Генерировать" onClick={handleRenewCode}>
									<Svg name="update" />
								</a>
								<label title="Загрузить код">
									<input type="file" onChange={handleUploadQR} />
									<Svg name="upload" className="w20" />
								</label>
							</div>
							{errors.code && <div className="error">{errors.code}</div>}
						</label>
					</fieldset>
					<fieldset className="fieldset">
						<label className="label">
							<legend>NFC экспоната:</legend>
							<input
								type="text"
								className="input"
								placeholder="NFC"
								value={nfc || ''}
								onChange={handleChangeNFC}
							/>
							{errors.nfc && <div className="error">{errors.nfc}</div>}
						</label>
					</fieldset>
					<fieldset className="fieldset">
						<label className="label">
							<legend>Описание экспоната до 5000 символов:</legend>
							<textarea
								className="textarea"
								placeholder="Описание"
								value={description || ''}
								onChange={handleChangeDescription}
							/>
							{errors.description && <div className="error">{errors.description}</div>}
						</label>
					</fieldset>
				</div>
				<div className="fieldset__col">
					<label>
						<legend>QR Код</legend>
						<div className="file-info qr" onClick={onOpenModal}>
							<QRCode size={200} value={code || ''} />
						</div>
					</label>
					<label className="label">
						<legend>Аудио гид:</legend>
						<div className="file-info">
							{renderMp3()}
							<input type="file" accept=".mp3" onChange={handleChangeMp3} />
							<span>Загрузить</span>
						</div>
					</label>
					<div className="exhibit-edit__images">
						<legend>Изображения</legend>
						<div className="exist">
							{(images || []).map(item => (
								<label className="image" key={item.id!}>
									<img src={`${urlBackend}/upload/${item.image!.path!}`} />
									<Svg name="cross" className="del" onClick={() => handleDeleteOldImage(item.id!)} />
								</label>
							))}
							{newImages.map((item, index) => (
								<label className="image" key={index}>
									<img src={item.preview} />
									<Svg name="cross" className="del" onClick={() => handleDeleteImage(index)} />
								</label>
							))}
						</div>
						<label className="add">
							<span className="add__icn">
								<Svg aria-label="presentation" name="file" />
							</span>
							<input
								ref={addRef}
								multiple
								type="file"
								accept=".jpg,.jpeg,.png"
								onChange={handleAddImage}
							/>
							<span className="add__txt small">Добавить файл...</span>
							<span className="add__btn">
								+<span> загрузить</span>
							</span>
						</label>
					</div>
				</div>
			</div>
			{preloader ? (
				<Preloader />
			) : (
				<div className="exhibit-edit__buttons">
					{id !== 'add' && (
						<button className="btn delete" onClick={handleDelete}>
							Удалить
						</button>
					)}
					<button className="btn submit" onClick={handleSave}>
						Сохранить
					</button>
				</div>
			)}
			<CommentsList exhibit={exhibit} />
			<Modal open={modalOpen} onClose={onCloseModal} center>
				<QRModal value={code || ''} title={rus_to_latin(`${museum.title}_${title}`)} />
			</Modal>
		</div>
	);
};

export default ExhibitEdit;
