import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthPage from 'components/AuthPage/AuthPage';
import MainPage from 'components/MainPage';
import IndexRoute from 'components/IndexRoute';

const App: React.FC = () => {
	return (
		<Switch>
			<Route exact path={'/'} component={IndexRoute} />
			<Route path="/auth" component={AuthPage} />
			<Route component={MainPage} />
		</Switch>
	);
};

export default App;
