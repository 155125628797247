import React from 'react';

import './check.scss';
import Svg from 'common/Svg';

export interface CheckBoxProps {
	label?: string;
	checked?: boolean;
	onClick?: () => void;
	disabled?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({ disabled, checked, onClick, label }) => {
	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		if (!disabled && onClick) onClick();
	};

	return (
		<div className={`check ${disabled ? 'disabled' : ''}`} onClick={handleClick}>
			<input disabled={disabled} type="checkbox" checked={checked && !disabled} />
			<span className="check__dop">
				<Svg name="check" />
			</span>
			<span className="check__txt">{label}</span>
		</div>
	);
};

export default CheckBox;
