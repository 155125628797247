import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';

import './auth-page.scss';
import { authIsAuthorizedSelector, login, authErrorSelector } from 'ducks/auth';
import Preloader from 'common/Preloader/Preloader';
import { prloadersPreloaderSelector } from 'ducks/ui/preloaders';

const AuthPage = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const isAuthorized = useSelector(authIsAuthorizedSelector);
	const error = useSelector(authErrorSelector, shallowEqual);
	const isPreloader = useSelector(prloadersPreloaderSelector);

	if (isAuthorized) {
		return <Redirect to="/" />;
	}

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		dispatch(login(email, password));
	};

	const handleButton = () => {
		dispatch(login(email, password));
	};

	return (
		<div className="auth-page">
			<div className="auth-page__wrap">
				<h1>Musetory</h1>
				<form onSubmit={handleSubmit}>
					<label>
						Email:
						<input type="email" value={email} onChange={handleChangeEmail} />
					</label>
					<label>
						Password:
						<input type="password" value={password} onChange={handleChangePassword} />
					</label>
				</form>
				{error && <div className="error">{error}</div>}
				{isPreloader ? <Preloader /> : <button onClick={handleButton}>Войти</button>}
			</div>
		</div>
	);
};

export default AuthPage;
