import React from 'react';
import { Link } from 'react-router-dom';

import Svg from 'common/Svg';
import UserDto from 'dto/UserDto';
import { getAvatar, getName } from 'utils/userHelper';

export interface UsersCatalogItemItemProps {
	user: UserDto;
}

const UsersCatalogItem: React.FC<UsersCatalogItemItemProps> = ({ user }) => {
	const {
		id,
		isAdmin,
		isGeust,
		seen_exhibits_count,
		seen_museums_count,
		likes_count,
		favorits_count,
		comments_count,
	} = user;

	return (
		<tr>
			<td className="nowrap">
				<img src={getAvatar(user)} />
			</td>
			<td className="big">{getName(user)}</td>
			<td className="nowrap">
				<strong>Экспонатов:</strong> {seen_exhibits_count || 0}
				<br />
				<strong>Музеев:</strong> {seen_museums_count || 0}
			</td>
			<td className="nowrap">
				<strong>Лайков:</strong> {likes_count || 0}
				<br />
				<strong>Избранное:</strong> {favorits_count || 0}
				<br />
				<strong>Комментариев:</strong> {comments_count || 0}
			</td>
			<td className="center">{isGeust && <Svg className="info-ico admin" name="check" />}</td>
			<td className="center">{isAdmin && <Svg className="info-ico admin" name="check" />}</td>
			<td>
				<div className="table__bar">
					<Link to={`/users/${id}`} className="table__btn">
						<Svg name="edit" />
					</Link>
				</div>
			</td>
		</tr>
	);
};

export default UsersCatalogItem;
