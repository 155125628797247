import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './pages.scss';
import { dataCommentsFilterSelector, dataCommentsTotalSelector, getAllComments, setFilter } from 'ducks/data/comments';
import { PerPage } from '../../config';

const CommentsPages: React.FC = () => {
	const dispatch = useDispatch();
	const total = useSelector(dataCommentsTotalSelector, shallowEqual);
	const { offset } = useSelector(dataCommentsFilterSelector, shallowEqual);

	const pagecount = total % PerPage > 0 ? parseInt(String(total / PerPage)) + 1 : total / PerPage;

	const pages: { page: number; active: boolean }[] = [];
	for (let index = 1; index <= pagecount; index++) {
		pages.push({ page: index, active: offset / PerPage + 1 === index });
	}

	if (pages.length <= 0) return null;

	return (
		<div className="pages">
			Страницы:
			{pages.map(item => {
				const handleClick = () => {
					dispatch(setFilter('offset', PerPage * (item.page - 1)));
					dispatch(getAllComments());
				};

				return (
					<a key={item.page} onClick={handleClick} className={`page ${item.active ? 'active' : ''}`}>
						{item.page}
					</a>
				);
			})}
		</div>
	);
};

export default CommentsPages;
