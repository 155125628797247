import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CommentDto from 'dto/CommentDto';

import './comment-item.scss';
import Svg from 'common/Svg';
import { removeComment } from 'ducks/data/comments';

export interface CommentItemProps {
	comment: CommentDto;
	userId: number;
}

const CommentItem: React.FC<CommentItemProps> = ({ comment, userId }) => {
	const dispatch = useDispatch();
	const { id, text, createdAt, exhibit } = comment;

	const handleDelete = () => {
		if (confirm('Удалить комментарий?')) {
			dispatch(removeComment(id!, userId));
		}
	};

	return (
		<div className="comment-item">
			<div className="comment-item__col big">
				<div className="comment-item__row">
					<div className="comment-item__col">{moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
					<div className="comment-item__col">к экспонату </div>
					<Link to={`/museum/exhibit/${exhibit!.museumId!}/${exhibit!.id!}`}>{exhibit?.title || ''}</Link>
				</div>
				{text}
			</div>
			<div className="comment-item__col">
				<a onClick={handleDelete}>
					<Svg name="cross" />
				</a>
			</div>
		</div>
	);
};

export default CommentItem;
