import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import { push } from 'connected-react-router';
import UploadDto from 'dto/UploadDto';
import UserDto from 'dto/UserDto';
import { getUsersList } from 'ducks/data/users';

export const moduleName = 'edit/user';

export const FETCH = `${moduleName}/FETCH`;
export const CLEAR_ERRORS = `${moduleName}/CLEAR_ERRORS`;
export const SET_FIELD = `${moduleName}/SET_FIELD`;
export const SET_ERROR_FIELD = `${moduleName}/SET_ERROR_FIELD`;

export const initialUser: UserDto = {
	avatar: null,
	email: '',
	password: null,
	name: null,
	isAdmin: false,
};

export interface EditUserErrorsState {
	email: string | null;
	password: string | null;
	repeat: string | null;
}

export const initialErrors: EditUserErrorsState = {
	email: null,
	password: null,
	repeat: null,
};

export interface EditUserState {
	user: UserDto;
	errors: EditUserErrorsState;
}

export const initialState: EditUserState = {
	user: initialUser,
	errors: initialErrors,
};

export interface EditUserAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		user?: UserDto;
		field?: keyof UserDto;
		errorField?: keyof EditUserErrorsState;
		value?: any;
	};
}

export default (state = initialState, action: EditUserAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, user: payload?.user || initialUser, errors: initialErrors };
		case CLEAR_ERRORS:
			return { ...state, errors: initialErrors };
		case SET_FIELD:
			return { ...state, user: { ...state.user, [payload!.field!]: payload?.value } };
		case SET_ERROR_FIELD:
			return { ...state, errors: { ...state.errors, [payload!.errorField!]: payload?.value } };
		default:
			return { ...state };
	}
};

export const fetch = (user: UserDto) => ({
	type: FETCH,
	payload: { user },
});

export const clearErrors = () => ({
	type: CLEAR_ERRORS,
});

export const setField = (field: keyof UserDto, value: any) => ({
	type: SET_FIELD,
	payload: { field, value },
});

export const setErrorField = (errorField: keyof EditUserErrorsState, value: any) => ({
	type: SET_ERROR_FIELD,
	payload: { errorField, value },
});

export const getUser = (id?: number) => (dispatch: AppDispatch) => {
	if (!id) dispatch(fetch(initialUser));
	else
		api.user.get(id).then(res => {
			if (res.success && res.data) {
				dispatch(fetch(res.data));
			}
		});
};

export const removeUser = (id: number) => (dispatch: AppDispatch) => {
	api.user.remove(id).then(() => {
		dispatch(getUsersList());
		dispatch(push('/users'));
	});
};

export const saveUser = () => (dispatch: AppDispatch, getState: () => AppState) => {
	const user = editUserEditSelector(getState());
	dispatch(clearErrors());
	let isErrors = false;
	if (!user.email || user.email.trim().length <= 0) {
		isErrors = true;
		dispatch(setErrorField('email', 'Поле не заполнено'));
	}
	if (!user.id && (!user.password || user.password.trim().length <= 0)) {
		isErrors = true;
		dispatch(setErrorField('password', 'Поле не заполнено'));
		if (!user.repeat || user.repeat.trim().length <= 0) {
			isErrors = true;
			dispatch(setErrorField('repeat', 'Поле не заполнено'));
		}
	}
	if (user.password) {
		if (!user.repeat || user.repeat.trim().length <= 0) {
			isErrors = true;
			dispatch(setErrorField('repeat', 'Поле не заполнено'));
		} else if (user.repeat !== user.password) {
			isErrors = true;
			dispatch(setErrorField('repeat', 'Введенные пароли не совпадают'));
		}
	}
	if (!isErrors) {
		delete user.repeat;
		let avatar: File | null = null;
		if (user.avatar && !(user.avatar as UploadDto).id) {
			avatar = user.avatar as File;
			delete user.avatar;
		}
		if (user.password === null) {
			delete user.password;
		}
		if (user.id) {
			api.user.update(user.id, user, avatar).then(() => {
				dispatch(getUsersList());
				dispatch(push('/users'));
			});
		} else {
			delete user.id;
			api.user.create(user, avatar).then(() => {
				dispatch(getUsersList());
				dispatch(push('/users'));
			});
		}
	}
};

const editSelector = (state: AppState) => state.edit;
export const editUserSelector = createSelector(editSelector, edit => edit.user);
export const editUserEditSelector = createSelector(editUserSelector, user => user.user);
export const editUserErrorsSelector = createSelector(editUserSelector, user => user.errors);
