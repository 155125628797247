import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Svg from 'common/Svg';
import { setNavBarOpen } from 'ducks/ui/status';
import { MenuItemType } from 'hooks/useMenu';
import { urlBackend } from '../../../../config';

export interface SubMenuItemProps {
	subitem: MenuItemType;
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({ subitem }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const handleClickItem = () => {
		if (subitem.onClick) {
			subitem.onClick();
		}
		dispatch(setNavBarOpen(false));
	};

	const isActive = () => {
		if (pathname === subitem.link) {
			if (subitem.active) return subitem.active();
			return true;
		}
		return false;
	};

	const renderIco = () => {
		if (subitem.ico) {
			if (subitem.ico.indexOf('/') >= 0) {
				return <img src={`${urlBackend}/upload/${subitem.ico}`} />;
			}
			return <Svg name={subitem.ico} />;
		}
		return <Svg name="plus" />;
	};

	return (
		<li>
			<Link
				to={subitem.link || '/'}
				className={`nav-bar__subitem ${isActive() ? 'active' : ''}`}
				onClick={handleClickItem}
			>
				<i>{renderIco()}</i>
				<span>{subitem.title || '????'}</span>
			</Link>
		</li>
	);
};

export default SubMenuItem;
