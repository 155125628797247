import { createSelector } from 'reselect';
import { AnyAction } from 'redux';
import { v4 as uuid } from 'uuid';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import { push } from 'connected-react-router';
import UploadDto from 'dto/UploadDto';
import ExhibitDto from 'dto/ExhibitDto';
import { getExhibitsList } from 'ducks/data/exhibits';

export const moduleName = 'edit/exhibit';

export const FETCH = `${moduleName}/FETCH`;
export const CLEAR_ERRORS = `${moduleName}/CLEAR_ERRORS`;
export const SET_FIELD = `${moduleName}/SET_FIELD`;
export const SET_ERROR_FIELD = `${moduleName}/SET_ERROR_FIELD`;
export const SET_PRELOADER = `${moduleName}/SET_PRELOADER`;

export const initialExhibit: ExhibitDto = {
	favorits: [],
	likes: [],
	museum: null,
	museumId: 0,
	images: [],
	mp3: null,
	title: '',
	description: null,
	nfc: null,
	code: uuid().toLowerCase(),
};

export interface EditExhibitErrorsState {
	title: string | null;
	code: string | null;
	nfc: string | null;
	description: string | null;
}

export const initialErrors: EditExhibitErrorsState = {
	title: null,
	code: null,
	nfc: null,
	description: null,
};

export interface EditExhibitState {
	edit: ExhibitDto;
	errors: EditExhibitErrorsState;
	preloader: boolean;
}

export const initialState: EditExhibitState = {
	edit: initialExhibit,
	errors: initialErrors,
	preloader: false,
};

export interface EditExhibitAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		edit?: ExhibitDto;
		field?: keyof ExhibitDto;
		errorField?: keyof EditExhibitErrorsState;
		value?: any;
		preloader?: boolean;
	};
}

export default (state = initialState, action: EditExhibitAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, edit: payload?.edit || initialExhibit, errors: initialErrors };
		case CLEAR_ERRORS:
			return { ...state, errors: initialErrors };
		case SET_FIELD:
			return { ...state, edit: { ...state.edit, [payload!.field!]: payload?.value || null } };
		case SET_ERROR_FIELD:
			return { ...state, errors: { ...state.errors, [payload!.errorField!]: payload?.value || null } };
		case SET_PRELOADER:
			return { ...state, preloader: payload?.preloader || false };
		default:
			return { ...state };
	}
};

export const fetch = (edit: ExhibitDto) => ({
	type: FETCH,
	payload: { edit },
});

export const clearErrors = () => ({
	type: CLEAR_ERRORS,
});

export const setPreloader = (preloader: boolean) => ({
	type: SET_PRELOADER,
	payload: { preloader },
});

export const setField = (field: keyof ExhibitDto, value: any) => ({
	type: SET_FIELD,
	payload: { field, value },
});

export const setErrorField = (errorField: keyof EditExhibitErrorsState, value: any) => ({
	type: SET_ERROR_FIELD,
	payload: { errorField, value },
});

export const getExhibit = (museumId: number, id?: number) => (dispatch: AppDispatch) => {
	if (!id) dispatch(fetch(initialExhibit));
	else
		api.exhibit.get(id).then(res => {
			if (res.success && res.data) {
				dispatch(fetch({ ...res.data, museumId }));
			}
		});
};

export const removeExhibit = (museumId: number, id: number) => (dispatch: AppDispatch) => {
	api.exhibit.remove(id).then(() => {
		dispatch(getExhibitsList(museumId));
		dispatch(push(`/museum/${museumId}`));
	});
};

export const saveExhibit = (museumId: number, newImages: File[]) => (
	dispatch: AppDispatch,
	getState: () => AppState,
) => {
	const edit = editExhibitEditSelector(getState());
	dispatch(clearErrors());
	let isErrors = false;
	if (!edit.title || edit.title.trim().length <= 0) {
		isErrors = true;
		dispatch(setErrorField('title', 'Поле не заполнено'));
	}
	if (!edit.code || edit.code.trim().length <= 0) {
		isErrors = true;
		dispatch(setErrorField('code', 'Поле не заполнено'));
	}
	if (edit.description && edit.description.length > 5000) {
		isErrors = true;
		dispatch(setErrorField('description', 'Не более 5000 символов'));
	}
	if (!isErrors) {
		dispatch(setPreloader(true));
		let mp3: File | null = null;
		if (edit.mp3 && !(edit.mp3 as UploadDto).id) {
			mp3 = edit.mp3 as File;
			delete edit.mp3;
		}
		edit.museumId = museumId;
		if (edit.id) {
			api.exhibit.update(edit.id, edit, mp3, newImages).then(res => {
				if (res.success) {
					dispatch(getExhibitsList(museumId));
					dispatch(push(`/museum/${museumId}`));
				} else if (res.error?.code === 501) {
					for (const key of Object.keys(res.error.info)) {
						dispatch(setErrorField(key as keyof EditExhibitErrorsState, res.error.info[key]));
					}
				}
				dispatch(setPreloader(false));
			});
		} else {
			api.exhibit.create(edit, mp3, newImages).then(res => {
				if (res.success) {
					dispatch(getExhibitsList(museumId));
					dispatch(push(`/museum/${museumId}`));
				} else if (res.error?.code === 501) {
					for (const key of Object.keys(res.error.info)) {
						dispatch(setErrorField(key as keyof EditExhibitErrorsState, res.error.info[key]));
					}
				}
				dispatch(setPreloader(false));
			});
		}
	}
};

const editSelector = (state: AppState) => state.edit;
export const editExhibitSelector = createSelector(editSelector, edit => edit.exhibit);
export const editExhibitEditSelector = createSelector(editExhibitSelector, exhibit => exhibit.edit);
export const editExhibitErrorsSelector = createSelector(editExhibitSelector, exhibit => exhibit.errors);
export const editExhibitPreloaderSelector = createSelector(editExhibitSelector, exhibit => exhibit.preloader);
