import React from 'react';
import { Link } from 'react-router-dom';

import MuseumDto from 'dto/MuseumDto';
import { urlBackend } from '../../../config';
import UploadDto from 'dto/UploadDto';
import Svg from 'common/Svg';

export interface MuseumCatalogItemProps {
	museum: MuseumDto;
}

const MuseumCatalogItem: React.FC<MuseumCatalogItemProps> = ({ museum }) => {
	const { id, title, ico, exhibits_count, rating } = museum;

	return (
		<tr>
			<td className="nowrap">
				{ico && (ico as UploadDto).path && <img src={`${urlBackend}/upload/${(ico as UploadDto).path}`} />}
			</td>
			<td className="big">{title}</td>
			<td>{rating}</td>
			<td>{exhibits_count}</td>
			<td>
				<div className="table__bar">
					<Link to={`/museum/${id}`} className="table__btn">
						<Svg name="list" />
					</Link>
					<Link to={`/museum/edit/${id}`} className="table__btn">
						<Svg name="edit" />
					</Link>
				</div>
			</td>
		</tr>
	);
};

export default MuseumCatalogItem;
