import React from 'react';

import { hostName } from '../../../config';

const getWarningText = () => {
	switch (window.location.hostname.toLowerCase()) {
		case 'testin.famil.ru':
			return 'TESTING SERVER';
		case 'localhost':
			return `DEVELOPER MACHINE ${hostName}`;
		default:
			return '';
	}
};

const HeaderWarning: React.FC = () => {
	return <div className="header__warning">{getWarningText()}</div>;
};

export default HeaderWarning;
