import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import './museum-catalog.scss';
import { dataMuseumsListSelector, getMuseumsList, getStat } from 'ducks/data/museums';
import MuseumCatalogItem from 'components/MuseumPage/MuseumCatalog/MuseumCatalogItem';
import Svg from 'common/Svg';

const MuseumCatalog: React.FC = () => {
	const dispatch = useDispatch();
	const museumsList = useSelector(dataMuseumsListSelector, shallowEqual);

	useEffect(() => {
		dispatch(getMuseumsList());
	}, []);

	const handleAdd = () => {
		dispatch(push('/museum/edit/add'));
	};

	const handleStat = () => {
		dispatch(getStat());
	};

	return (
		<div className="museum-catalog">
			<div className="museum-catalog__filter">
				<legend className="legend sec-title">Список музеев</legend>
				<button className="btn submit" onClick={handleStat} style={{ marginRight: '10px' }}>
					Статистика
				</button>
				<button className="btn submit" onClick={handleAdd}>
					<Svg name="plus" />
					Добавить
				</button>
			</div>
			{museumsList && museumsList.length > 0 && (
				<div className="table">
					<div className="table__wrap">
						<table>
							<thead>
								<tr>
									<th className="museum-catalog__ico-field">
										<div className="table__head">Аватар</div>
									</th>
									<th className="big">
										<div className="table__head">Название</div>
									</th>
									<th>
										<div className="table__head">Рейтинг</div>
									</th>
									<th>
										<div className="table__head">Экспонатов</div>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{museumsList.map(item => (
									<MuseumCatalogItem museum={item} key={item.id} />
								))}
							</tbody>
						</table>
					</div>
				</div>
			)}
		</div>
	);
};

export default MuseumCatalog;
