import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './users-catalog.scss';
import Svg from 'common/Svg';
import { dataUsersFilterSelector, dataUsersListSelector, getUsersList, setFilterField } from 'ducks/data/users';
import UsersCatalogItem from 'components/UsersPage/UsersCatalog/UsersCatalogItem';
import CheckBox from 'common/CheckBox/CheckBox';

const UsersCatalog: React.FC = () => {
	const dispatch = useDispatch();
	const usersList = useSelector(dataUsersListSelector, shallowEqual);
	const { isadmin, isgeust, isreg } = useSelector(dataUsersFilterSelector, shallowEqual);

	useEffect(() => {
		dispatch(getUsersList());
	}, []);

	const handleAdd = () => {
		dispatch(push('/users/add'));
	};

	const handleChangeIsAdmin = () => {
		dispatch(setFilterField('isadmin', !isadmin));
		dispatch(getUsersList());
	};

	const handleChangeIsGeust = () => {
		dispatch(setFilterField('isgeust', !isgeust));
		dispatch(getUsersList());
	};

	const handleChangeIsReg = () => {
		dispatch(setFilterField('isreg', !isreg));
		dispatch(getUsersList());
	};

	return (
		<div className="users-catalog">
			<div className="users-catalog__filter">
				<legend className="legend sec-title">Список пользователей</legend>
				<div className="users-catalog__filter-data">
					<CheckBox label="Администраторы" checked={isadmin} onClick={handleChangeIsAdmin} />
					<CheckBox label="Зарегистрированные" checked={isreg} onClick={handleChangeIsReg} />
					<CheckBox label="Гости" checked={isgeust} onClick={handleChangeIsGeust} />
				</div>
				<button className="btn submit" onClick={handleAdd}>
					<Svg name="plus" />
					Добавить
				</button>
			</div>
			{usersList && usersList.length > 0 && (
				<div className="table">
					<div className="table__wrap">
						<table>
							<thead>
								<tr>
									<th className="users-catalog__ico-field">
										<div className="table__head">Аватар</div>
									</th>
									<th className="big">
										<div className="table__head">Имя</div>
									</th>
									<th>
										<div className="table__head">Посещено</div>
									</th>
									<th>
										<div className="table__head">Профиль</div>
									</th>
									<th>
										<div className="table__head">Гость</div>
									</th>
									<th>
										<div className="table__head">Администратор</div>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{usersList.map(item => (
									<UsersCatalogItem user={item} key={item.id} />
								))}
							</tbody>
						</table>
					</div>
				</div>
			)}
		</div>
	);
};

export default UsersCatalog;
