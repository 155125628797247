import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MuseumCatalog from 'components/MuseumPage/MuseumCatalog/MuseumCatalog';
import MuseumEdit from 'components/MuseumPage/MuseumEdit/MuseumEdit';
import ExhibitsCatalog from 'components/MuseumPage/ExhibitsCatalog/ExhibitsCatalog';
import ExhibitEdit from 'components/MuseumPage/ExhibitEdit/ExhibitEdit';

const MuseumPage: React.FC = () => {
	return (
		<div className="content">
			<Switch>
				<Route exact path="/museum" component={MuseumCatalog} />
				<Route exact path="/museum/exhibit/:museumId/:id" component={ExhibitEdit} />
				<Route exact path="/museum/:id" component={ExhibitsCatalog} />
				<Route exact path="/museum/edit/:id" component={MuseumEdit} />
			</Switch>
		</div>
	);
};

export default MuseumPage;
