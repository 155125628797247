import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import UserDto from 'dto/UserDto';

export const moduleName = 'data/users';

export const FETCH = `${moduleName}/FETCH`;
export const SET_FILTER_FIELD = `${moduleName}/SET_FILTER_FIELD`;

export interface DataUsersFilter {
	isadmin: boolean;
	isgeust: boolean;
	isreg: boolean;
}

export const initialFilter: DataUsersFilter = {
	isadmin: true,
	isgeust: true,
	isreg: true,
};

export interface DataUsersState {
	list: UserDto[];
	filter: DataUsersFilter;
}

export const initialState: DataUsersState = {
	list: [],
	filter: initialFilter,
};

export interface DataUsersAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: UserDto[];
		filterField?: keyof DataUsersFilter;
		value?: any;
	};
}

export default (state = initialState, action: DataUsersAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [] };
		case SET_FILTER_FIELD:
			return { ...state, filter: { ...state.filter, [payload!.filterField!]: payload!.value! } };
		default:
			return { ...state };
	}
};

export const fetch = (list: UserDto[]) => ({
	type: FETCH,
	payload: { list },
});

export const setFilterField = (filterField: keyof DataUsersFilter, value: any) => ({
	type: SET_FILTER_FIELD,
	payload: { filterField, value },
});

export const getUsersList = () => (dispatch: AppDispatch, getState: () => AppState) => {
	const { isadmin, isgeust, isreg } = dataUsersFilterSelector(getState());
	api.user.list(isadmin, isgeust, isreg).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
	});
};

const dataSelector = (state: AppState) => state.data;
export const dataUsersSelector = createSelector(dataSelector, data => data.users);
export const dataUsersListSelector = createSelector(dataUsersSelector, users => users.list);
export const dataUsersFilterSelector = createSelector(dataUsersSelector, users => users.filter);
