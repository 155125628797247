import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { dataCommentsListSelector, getExhibitComments } from 'ducks/data/comments';
import ExhibitDto from 'dto/ExhibitDto';
import CommentItem from './CommentItem';
import CommentsPages from 'components/MuseumPage/ExhibitEdit/CommentsList/CommentsPages';

export interface CommentsListProps {
	exhibit: ExhibitDto;
}

const CommentsList: React.FC<CommentsListProps> = ({ exhibit }) => {
	const dispatch = useDispatch();
	const list = useSelector(dataCommentsListSelector, shallowEqual);

	useEffect(() => {
		if (exhibit.id) dispatch(getExhibitComments(exhibit.id));
	}, [exhibit]);

	if (!list || list.length <= 0) return null;

	return (
		<section>
			<legend className="legend sec-title">Комментарии к экспонату</legend>
			<CommentsPages exhibitId={exhibit.id!} />
			<div className="comments-list fieldset__row">
				<div className="fieldset__col">
					{list.map(item => (
						<CommentItem key={item.id} comment={item} exhibitId={exhibit.id!} />
					))}
				</div>
			</div>
		</section>
	);
};

export default CommentsList;
