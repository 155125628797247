import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

/**
 * Constants
 **/
export const moduleName = 'ui/prloaders';

export const SET_PRELOADER = `${moduleName}/SET_PRELOADER`;

export interface PreloadersState {
	isPreloader: boolean;
}

export interface PreloadersAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		preloader?: boolean;
	};
}

export const initialState: PreloadersState = {
	isPreloader: false,
};

/**
 * Reducer
 **/
export default (state: PreloadersState = initialState, action: PreloadersAction): PreloadersState => {
	const { type, payload } = action;

	switch (type) {
		case SET_PRELOADER:
			return { ...state, isPreloader: payload?.preloader || false };
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const setPreloader = (preloader: boolean): PreloadersAction => ({
	type: SET_PRELOADER,
	payload: { preloader },
});

/**
 * Selectors
 **/
const uiSelector = (state: { ui: { preloaders: PreloadersState } }): { preloaders: PreloadersState } => state.ui;
export const preloadersSelector = createSelector(uiSelector, ui => ui.preloaders);
export const prloadersPreloaderSelector = createSelector(
	preloadersSelector,
	(preloaders: PreloadersState) => preloaders.isPreloader,
);
