import axios from 'axios';
import { stringify } from 'qs';
import { Store } from 'redux';

import { urlBackend } from '../config';
import storage from './localStorage';
import { logout } from 'ducks/auth';
import { AppState } from '../redux/reducer';
import configureStore from '../redux/configureStore';
import localStorage from 'api/localStorage';

export default (): Store<AppState, any> => {
	axios.defaults.baseURL = `${urlBackend}/api`;
	axios.defaults.paramsSerializer = (params: any): string => stringify(params, { encode: false });

	const store: Store<AppState, any> = configureStore({
		ui: localStorage.fetchInterface(),
		auth: localStorage.fetchAuth(),
	});

	const { isAuthorized, token } = store.getState().auth;
	if (isAuthorized) {
		axios.defaults.headers.common.Authorization = token;
	}

	axios.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			if (error.code && error.code === 'ECONNABORTED') {
				return { success: false, error: { code: 400 } };
			}
			if (error.response && error.response.status === 401) {
				store.dispatch(logout());
			}
			return Promise.reject(error);
		},
	);

	store.subscribe(() => {
		const { ui, auth } = store.getState();
		storage.saveInterface(ui);
		storage.saveAuth(auth);
	});

	return store;
};
