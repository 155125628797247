import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { YMaps, Map, ZoomControl, Placemark } from 'react-yandex-maps';

import './city-edit.scss';
import Preloader from 'common/Preloader/Preloader';
import { $city, $loadingCityes, changeCityField, clearCity, getCity, removeCity, saveCity } from 'models/city';
import { useStore } from 'effector-react';

const CityEdit: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [pageTitle, setPageTile] = useState('Добавление города');
	const [errorTitle, setErrorTitle] = useState<string | null>(null);
	const [errorLatLon, setErrorLatLon] = useState<string | null>(null);
	const city = useStore($city);
	const loading = useStore($loadingCityes);

	useEffect(() => {
		if (id === 'add') {
			setPageTile('Добавление города');
			clearCity();
		} else {
			setPageTile('Редактирование города');
			getCity(parseInt(id));
		}
	}, [id]);

	const { title, lon, lat } = city;

	const handleChangePlace = (evnt: any) => {
		setErrorLatLon(null);
		const [nlat, nlon] = evnt.get('coords');
		changeCityField({ key: 'lat', value: nlat });
		changeCityField({ key: 'lon', value: nlon });
	};

	const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setErrorTitle(null);
		changeCityField({ key: 'title', value: event.target.value });
	};

	const handleDelete = () => {
		if (confirm('Вы уверены?')) {
			removeCity(parseInt(id));
		}
	};

	const handleSave = () => {
		let isError = false;
		if (!title || title.trim().length <= 0) {
			isError = true;
			setErrorTitle('Поле не заполнено');
		}
		if (!lat || !lon) {
			isError = true;
			setErrorLatLon('Поле не заполнено');
		}
		if (!isError) {
			saveCity(city);
		}
	};

	if (loading) return <Preloader />;

	return (
		<YMaps>
			<div className="museum-edit">
				<legend className="legend sec-title">{pageTitle}</legend>
				<div className="fieldset__row">
					<div className="fieldset__col">
						<fieldset className="fieldset">
							<label className="label">
								<legend>Название города:</legend>
								<input
									type="text"
									className="input"
									placeholder="Название"
									value={title}
									onChange={handleChangeTitle}
								/>
								{errorTitle && <div className="error">{errorTitle}</div>}
							</label>
						</fieldset>
					</div>
					<div className="fieldset__col">
						<label className="label">
							<legend>Координаты:</legend>
							<Map
								width="100%"
								height={400}
								defaultState={{
									center: [lat ? parseFloat(lat) : 55.75, lon ? parseFloat(lon) : 37.57],
									zoom: 9,
								}}
								onClick={handleChangePlace}
							>
								<ZoomControl options={{ float: 'right' }} />
								{/*<SearchControl options={{ float: 'right' }} />*/}
								{lat && lon && (
									<Placemark
										defaultGeometry={[lat ? parseFloat(lat) : 55.75, lon ? parseFloat(lon) : 37.57]}
										geometry={[lat ? parseFloat(lat) : 55.75, lon ? parseFloat(lon) : 37.57]}
									/>
								)}
							</Map>
							{errorLatLon && <div className="error">{errorLatLon}</div>}
						</label>
					</div>
				</div>
				<div className="museum-edit__buttons">
					{id !== 'add' && (
						<button className="btn delete" onClick={handleDelete}>
							Удалить
						</button>
					)}
					<button className="btn submit" onClick={handleSave}>
						Сохранить
					</button>
				</div>
			</div>
		</YMaps>
	);
};

export default CityEdit;
