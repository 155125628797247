import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import CommentDto from 'dto/CommentDto';

export const moduleName = 'data/comments';

export const FETCH = `${moduleName}/FETCH`;
export const SET_FILTER = `${moduleName}/SET_FILTER`;

export interface DataCommentsFilter {
	offset: number;
}

export const initialFilter: DataCommentsFilter = {
	offset: 0,
};

export interface DataCommentsState {
	list: CommentDto[];
	total: number;
	filter: DataCommentsFilter;
}

export const initialState: DataCommentsState = {
	list: [],
	total: 0,
	filter: initialFilter,
};

export interface DataCommentsAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: CommentDto[];
		field?: keyof DataCommentsFilter;
		total?: number;
		value?: any;
	};
}

export default (state = initialState, action: DataCommentsAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [], total: payload?.total || 0 };
		case SET_FILTER:
			return { ...state, filter: { ...state.filter, [`${payload!.field}`]: payload!.value } };
		default:
			return { ...state };
	}
};

export const fetch = (list: CommentDto[], total: number) => ({
	type: FETCH,
	payload: { list, total },
});

export const setFilter = (field: keyof DataCommentsFilter, value: any) => ({
	type: SET_FILTER,
	payload: { field, value },
});

export const getUserComments = (userId: number) => (dispatch: AppDispatch, getState: () => AppState) => {
	const { offset } = getState().data.comments.filter;
	api.comment.user(userId, offset).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data.list, res.data.total.count));
		} else {
			dispatch(fetch([], 0));
		}
	});
};

export const getExhibitComments = (userId: number) => (dispatch: AppDispatch, getState: () => AppState) => {
	const { offset } = getState().data.comments.filter;
	api.comment.exhibit(userId, offset).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data.list, res.data.total.count));
		} else {
			dispatch(fetch([], 0));
		}
	});
};

export const getAllComments = () => (dispatch: AppDispatch, getState: () => AppState) => {
	const { offset } = getState().data.comments.filter;
	api.comment.all(offset).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data.list, res.data.total.count));
		} else {
			dispatch(fetch([], 0));
		}
	});
};

export const removeComment = (id: number, targetId: number | null, type: 'user' | 'exhibit' = 'user') => (
	dispatch: AppDispatch,
) => {
	api.comment.remove(id).then(() => {
		if (!targetId) {
			dispatch(getAllComments());
		} else if (type === 'user') {
			dispatch(getUserComments(targetId));
		} else {
			dispatch(getExhibitComments(targetId));
		}
	});
};

const dataSelector = (state: AppState) => state.data;
export const dataCommentsSelector = createSelector(dataSelector, data => data.comments);
export const dataCommentsListSelector = createSelector(dataCommentsSelector, comments => comments.list);
export const dataCommentsFilterSelector = createSelector(dataCommentsSelector, comments => comments.filter);
export const dataCommentsTotalSelector = createSelector(dataCommentsSelector, comments => comments.total);
