import { combineReducers } from 'redux';

import preloaders, { PreloadersState, initialState as PreloadersInitialState } from './preloaders';
import status, { UiStatusState, initialState as UiStatusInitialState } from 'ducks/ui/status';

export interface UiState {
	preloaders: PreloadersState;
	status: UiStatusState;
}
export const uiSelector = (state: { ui: UiState }): UiState => state.ui;

export const initialState: UiState = {
	preloaders: PreloadersInitialState,
	status: UiStatusInitialState,
};

export default combineReducers({
	preloaders,
	status,
});
