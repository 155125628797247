import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './profile-bar.scss';
import Svg from 'common/Svg';
import { authUserSelector, logout } from 'ducks/auth';
import { getAvatar, getName } from 'utils/userHelper';

const ProfileBar: React.FC = () => {
	const dispatch = useDispatch();
	const user = useSelector(authUserSelector, shallowEqual);

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<div className="profile-bar">
			<div className="profile-bar__btn">
				<span className="profile-bar__avatar">
					<img src={getAvatar(user!)} />
				</span>
				<div className="profile-bar__main">
					<a className="profile-bar__drop">
						{getName(user!)}
						<i>
							<Svg name="chevrone-drop-down" />
						</i>
					</a>
					<div className="profile-bar__login">{user!.email}</div>
				</div>
			</div>
			<div className="profile-bar__hide">
				<nav className="profile-bar__nav">
					<ul>
						<li>
							<a onClick={handleLogout} className="profile-bar__item">
								<i className="profile-bar__item-icn">
									<Svg name="logout" />
								</i>
								<span>Выйти из аккаунта</span>
							</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default ProfileBar;
